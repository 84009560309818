// 西班牙语
export default {
  reward2: {
    desc: {
      title1: 'Nodos DeepLink',
      text1: 'Solo los propietarios de nodos pueden participar en la minería de tráfico de CPU y en la minería de máquinas GPU personales. La recompensa total es del 12% en tokens DLC, de los cuales el 4% es para nodos de tráfico de CPU y el 8% para nodos GPU personales. El número total de tokens es de 12 mil millones. La producción por nodo depende del número de nodos vendidos. Si se venden todos, cada nodo producirá 100,000 DLC. Si solo se venden el 10% de los nodos, cada nodo producirá 1 millón de DLC.',
      title2: 'Número total de nodos: 120,000  sin emisión adicional',
      text2: 'Airdrop adicional por nodo',
      text3: 'Cantidad mínima de DLC extraída por nodo',
      text4: 'Precio inicial',
      text5: 'Precio máximo'
    },
    btn_text: 'Comprar nodos',
    table: {
      tier: 'Nivel',
      license: 'Precio de la licencia de nodo (USDT)',
      total_num: 'Licencias totales',
      total_usdt: 'USDT totales',
      buy: 'Comprar'
    },
    intro: {
      title: 'Introducción a la minería de nodos',
      text1_1: 'Cuantos más usuarios tenga la red DeepLink, más tráfico de red y capacidad de cálculo se necesitarán, lo que requerirá más máquinas de tráfico de CPU y más máquinas de cálculo de GPU para proporcionar la infraestructura a la red DeepLink. Esto permite la descentralización y ayuda a DeepLink a desarrollarse más rápidamente. Al comprar una licencia de nodo, tendrás la oportunidad de participar en la minería y ganar recompensas. ¿Tienes preguntas? Ven',
      text1_2: 'y',
      text1_3: 'chatea con nosotros arriba.',
      text2: 'Cada nodo se emite en forma de NFT y, además, recibe un airdrop de 30 000 DLC con la cadena BSC. El airdrop comienza antes de la inclusión en el exchange, con el 20 % del DLC del airdrop desbloqueado inmediatamente y el 80 % desbloqueado en 8 meses. \n (Ver el proceso del token DLC: abre la billetera y selecciona la red BSC, selecciona Agregar token e ingresa la dirección del contrato: 0xD610Be30b06A3828A27D608a38F1E85faAFdEDcC)',
      text3: 'Los nodos no pueden revenderse a otros antes del 31 de octubre de 2024.',
      text4: 'El 25% de los tokens minados se desbloquean inmediatamente, mientras que el 75% se desbloquea linealmente en 150 días.',
      text5: 'Si la minería no comienza dentro de los 10 días posteriores a la cotización, las recompensas se distribuirán en forma de airdrops, que se realizarán cada 7 días. Las reglas del airdrop son las mismas que las reglas de producción de minería, con una producción total diaria de 4.1 millones de DLC, distribuida uniformemente según la cantidad de nodos vendidos. El 25% se desbloquea inmediatamente, y el 75% se desbloquea linealmente en 150 días. Si la minería ya ha comenzado, se requerirán las máquinas correspondientes para recibir recompensas.',
      text6: 'En el caso de compras de más de 20 nodos, se pueden delegar las operaciones de los mismos. Los inversores que compren más de 1.000 nodos de forma individual recibirán un conjunto completo de asistencia para la operación de los nodos, así como la posibilidad de conectarse con un operador cooperativo para ayudar a gestionar los nodos.',
      text7: 'Una máquina virtual puede admitir 20 nodos NFT y los ingresos se multiplican por 20.'
    },
    question: {
      ques1: '¿Cuántos NFT de nodos se venden en total?',
      ans1: 'Un total de 120,000',
      ques2: '¿Cuánto cuesta cada NFT de nodo?',
      ans2: 'Se divide en 10 niveles, con precios que van desde 30U hasta 155U, vendidos en el sitio web oficial hasta agotar existencias',
      ques3: '¿Es la compra de un NFT de nodo un pago único?',
      ans3: 'Sí, la compra de un nodo es un pago único, no se requieren tarifas adicionales en el futuro',
      ques4: '¿Hay airdrops adicionales al comprar un NFT de nodo?',
      ans4: 'Sí, cada propietario de nodo recibe un airdrop de 30,000 DLC. El airdrop comienza antes de la cotización en la bolsa, con un 20% de los DLC desbloqueados inmediatamente y un 80% desbloqueado en 8 meses',
      ques5: '¿Cuál es el mecanismo específico de minería?',
      ans5: 'La recompensa total es de 12 mil millones de DLC. Las ganancias totales para los nodos de tráfico son de 4 mil millones de DLC, y las ganancias totales para los nodos de máquinas GPU personales son de 8 mil millones de DLC. Al comprar un NFT de nodo, puedes participar simultáneamente en ambos tipos de minería. La recompensa para los primeros 4 años es de 1.5 mil millones de DLC por año, con una reducción a la mitad cada 4 años. La recompensa total diaria es de 4.11 millones de DLC, distribuida solo a los propietarios de NFT de nodo. El 25% de los tokens minados se desbloquean inmediatamente, mientras que el 75% se desbloquea linealmente en 150 días',
      ques6: '¿Un NFT de nodo comprado puede participar simultáneamente en la minería de máquina de tráfico de CPU y en la minería de máquina personal de GPU?',
      ans6: 'Sí',
      ques7: '¿Qué es un nodo de máquina de tráfico de ancho de banda de CPU?',
      ans7: 'El nodo de máquina de tráfico de ancho de banda de CPU asume la tarea principal de cálculo y reenvío de tráfico en la red DeepLink. A través del modelo de minería, DeepLink permite que más personas participen y ganen recompensas. Las ganancias totales para los nodos de tráfico son de 4 mil millones de DLC',
      ques8: '¿Cuál es la recompensa total para las máquinas de tráfico de ancho de banda en el primer año?',
      ans8: 'La recompensa para el primer año es de 500 millones de DLC, con aproximadamente 1.37 millones de DLC recompensados diariamente',
      ques9: '¿Cómo se relaciona el ancho de banda de cada nodo de máquina de tráfico de ancho de banda de CPU con sus ganancias?',
      ans9: 'El ancho de banda base es de 10Mb de ancho de banda de subida. El valor de ancho de banda es de 10 para un ancho de banda de subida de 10Mb. El ancho de banda solo es válido en múltiplos de 10, por ejemplo, un ancho de banda de subida de 45Mb se calcula como 40 en valor de ancho de banda. El ancho de banda aumenta un 30% según el uso real. Finalmente, las ganancias se dividen según el valor real del ancho de banda',
      ques10: '¿Son altas las exigencias de configuración para los nodos de máquina de tráfico de ancho de banda? ¿Hay información de configuración? ¿Cómo se participa en la minería?',
      ans10: 'Las exigencias de configuración no son altas. vCPU 1C + 2G de memoria + 50G de disco duro. El alquiler en AWS cuesta alrededor de 10 USD por mes, el ancho de banda se cobra según el uso real. Después de alquilar una máquina virtual CPU en AWS, instala el programa de minería de nodo de tráfico DeepLink, crea una dirección de billetera, transfiere el NFT de nodo a la billetera y activa la función de minería para participar en la minería',
      ques11: '¿Qué es un nodo de máquina personal de GPU?',
      ans11: 'Los individuos pueden ofrecer sus computadoras GPU para participar en la minería. Las máquinas GPU proporcionan servicios de infraestructura de juegos en la nube para el Protocolo DeepLink. Las ganancias totales para los nodos de máquinas GPU personales son de 8 mil millones de DLC',
      ques12: '¿Cuál es la recompensa total para los nodos de máquinas GPU personales en el primer año?',
      ans12: 'La recompensa para el primer año es de 1 mil millones de DLC, con aproximadamente 2.74 millones de DLC recompensados diariamente',
      ques13: '¿Cuáles son los requisitos de configuración para los nodos de máquinas GPU personales? ¿Cómo se participa en la minería?',
      ans13: 'Los requisitos de configuración para las máquinas GPU son: frecuencia de CPU de al menos 3.0GHz, GPU de la serie Nvidia Geforce: 20xx, 30xx, 40xx, y al menos 16G de memoria'
    }
  }
}