<template>
  <div class="header" :class="{lan_En : lan != 'zh'}">
    <div class="meau" :class="{ w1200: isphone && href == '/Protocol' }">
      <div class="content hide">
        <img class="logo" src="../assets/image_img/logo.png" alt="deeplink logo" @click="linkHref('/')">
        <div class="nav">
          <!-- <div class="nav1" :class="{active: href == '/'}" @click="linkHref('/')">{{$t('meau.nav1')}}</div> -->
          <div class="nav1" :class="{active: href == '/internet'}" @click="linkHref('/internet')">{{$t('meau.nav6')}}</div>
          <div class="nav1" :class="{active: href == '/gamefi'}" @click="linkHref('/gamefi')">{{$t('meau.nav7')}}</div>
          <div class="nav1" :class="{active: href == '/nft'}" @click="linkHref('/nft')">{{$t('meau.nav5')}}</div>
          <!-- <div class="nav_new"> 
            <el-dropdown :class="{active: (hre f == '/internet' || href == '/gamefi' || href == '/nft')}" popper-class="demo-dropdown" @command="linkHref2">
              <span class="el-dropdown-link">{{meau_text}}</span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="/internet">{{$t('meau.nav6')}}</el-dropdown-item>
                  <el-dropdown-item command="/gamefi">{{$t('meau.nav7')}}</el-dropdown-item>
                  <el-dropdown-item command="/nft">{{$t('meau.nav5')}}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <img class="icon2" src="../assets/arrow.png" alt="">
          </div> -->
          <div class="nav1" :class="{active: href == '/node'}" @click="linkHref('/node')">{{$t('meau.nav4')}}</div>
          <a class="nav1" href="https://www.dbcwallet.io/" target="_blank">Wallet</a>
          <a v-if="lan != 'zh'" class="nav1" href="https://www.deeplink.cloud/DeepLink_Whitepaper.pdf" target="target">WHITEPAPER</a>
          <div class="nav1" :class="{active: href == '/software'}" @click="linkHref('/software')">Download DeepLink</div>
          <!-- <div class="nav1" :class="{active: href == '/blog'}" @click="linkHref('/blog')">Blog</div> -->
        </div>
        <div class="nav2">
          <img class="icon1" src="../assets/image_img/country.png" alt="">
          <el-dropdown popper-class="demo-dropdown" @command="handleCommand">
            <span class="el-dropdown-link">{{text}}</span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="en">ENGLISH</el-dropdown-item>
                <!-- <el-dropdown-item command="zh">简体中文</el-dropdown-item> -->
                <el-dropdown-item command="ko">한국어</el-dropdown-item>
                <el-dropdown-item command="ja">日本語</el-dropdown-item>
                <el-dropdown-item command="ru">Русский</el-dropdown-item>
                <el-dropdown-item command="vn">Việt nam</el-dropdown-item>
                <el-dropdown-item command="es">Español</el-dropdown-item>
                <el-dropdown-item command="fr">Français</el-dropdown-item>
                <el-dropdown-item command="de">Deutsch</el-dropdown-item>
                <el-dropdown-item command="tr">Türkçe</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <img class="icon2" src="../assets/arrow.png" alt="">
        </div>
      </div>
      <div class="content show">
        <img class="logo" src="../assets/mobile/logo.png" alt="deeplink logo" @click="linkHref('/')">
        <div class="nav1 active" @click="linkHref1()">{{meauText}}<img class="icon2" src="../assets/arrow.png" alt=""></div>
        <div class="nav2" @click="drawer1 = true">
          <img class="icon1" src="../assets/icon.png" alt="">{{text}}<img class="icon2" src="../assets/arrow.png" alt="">
        </div>
      </div>
      <el-drawer v-model="drawer" custom-class="drawerInfo" :lock-scroll="true" :with-header="false" direction="btt">
        <template #default>
          <div class="meauInfo">
            <div class="meaulist" :class="{active: href == '/'}" @click="linkHref('/')">{{$t('meau.nav1')}}</div>
            <div class="meaulist" :class="{active: href == '/internet'}" @click="linkHref('/internet')">{{$t('meau.nav6')}}</div>
            <div class="meaulist" :class="{active: href == '/gamefi'}" @click="linkHref('/gamefi')">{{$t('meau.nav7')}}</div>
            <div class="meaulist" :class="{active: href == '/node'}" @click="linkHref('/node')">{{$t('meau.nav4')}}</div>
            <a class="meaulist" href="https://www.dbcwallet.io/" target="_blank">Wallet</a>
            <div class="meaulist" :class="{active: href == '/nft'}" @click="linkHref('/nft')">{{$t('meau.nav5')}}</div>
            <div class="meaulist" :class="{active: href == '/software'}" @click="linkHref('/software')">DeepLink Software</div>
            <!-- <div class="meaulist" :class="{active: href == '/blog'}" @click="linkHref('/blog')">Blog</div> -->
            <!-- <div v-if="lan != 'zh'"  class="meaulist" :class="{active: href == '/Protocol'}" @click="linkHref('/Protocol')">DeepLink Protocol</div> -->
          </div>
        </template>
      </el-drawer>
      <el-drawer v-model="drawer1" custom-class="drawerInfo" :lock-scroll="true" :with-header="false" direction="btt">
        <template #default>
          <div class="meauInfo">
            <div class="meaulist" @click="handleCommand('en')">ENGLISH</div>
            <!-- <div class="meaulist" @click="handleCommand('zh')">简体中文</div> -->
            <div class="meaulist" @click="handleCommand('ko')">한국어</div>
            <div class="meaulist" @click="handleCommand('ja')">日本語</div>
            <div class="meaulist" @click="handleCommand('ru')">Русский</div>
            <div class="meaulist" @click="handleCommand('vn')">Việt nam</div>

            <div class="meaulist" @click="handleCommand('es')">Español</div>
            <div class="meaulist" @click="handleCommand('fr')">Français</div>
            <div class="meaulist" @click="handleCommand('de')">Deutsch</div>
            <div class="meaulist" @click="handleCommand('tr')">Türkçe</div>
          </div>
        </template>
      </el-drawer>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, onMounted ,computed, watch} from "vue";
  import { useI18n } from "vue-i18n";
  import { useRoute, useRouter } from 'vue-router'
  import twitter from "../assets/twitter.jpg";
  import telegram from "../assets/telegram.jpg";
  import tiktok from "../assets/tiktok.jpg";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'Header',
    setup() {
      const { t, locale } = useI18n()
      const router = useRouter()
      const route = useRoute()
      const store = useStore()
      let lan = computed(() => store.state.lan)
      const text = ref('ENGLISH')
      const meau_text = ref(t('meau.nav5'))
      const dialogTableVisible = ref(false)
      const ishover = ref(false)
      const dialogTitle = ref('')
      const lanObj = {
        'en': 'ENGLISH',
        'ko': '한국어',
        'zh': '简体中文',
        'ja': '日本語',
        'ru': 'Русский',
        'vn': 'Việt nam',

        'es': 'Español',
        'fr': 'Français',
        'de': 'Deutsch',
        'tr': 'Türkçe'
      }
      const hrefObj = {
        'Twitter': {
          code: twitter,
          link: 'https://twitter.com/DeepLinkGlobal'
        },
        'Telegram': {
          code: telegram,
          link: 'https://t.me/deeplinkglobal'
        },
        'Telegram_Japan': {
          // code: telegram,
          link: 'https://t.me/DeepLinkJapan'
        },
        'Telegram_Africa': {
          // code: telegram,
          link: 'https://t.me/DeepLinkAfrica'
        },
        'Telegram_Vietnamese': {
          // code: telegram,
          link: 'https://t.me/DeepLinkVietnamese'
        },
        'Telegram_India': {
          // code: telegram,
          link: 'https://t.me/DeepLinkIndia'
        },
        'Discord': {
          code: '',
          link: 'https://discord.com/invite/hCSAF3QC8U'
        },
        'TikTok': {
          code: tiktok,
          link: 'https://www.tiktok.com/@deeplinkglobal'
        },
        'Reddit': {
          code: '',
          link: ''
        },
        'Medium': {
          code: '',
          link: 'https://medium.com/@DeepLinkGlobal'
        },
        'YouTube': {
          code: '',
          link: 'https://youtube.com/@deeplinkglobal'
        },
        'Instagram': {
          code: '',
          link: 'https://instagram.com/deeplinkglobal',
        },
        'Facebook': {
          code: '',
          link: 'https://www.facebook.com/deeplinkcloud',
        }
      }
      const showObj = ref({})
      const href = ref('/')
      const meauText = ref('')
      const handleCommand = (command) => {
        text.value = lanObj[command]
        locale.value = command
        localStorage.setItem('lan', command)
        store.commit('changeLan', command)
        drawer1.value = false
        if (href.value == '/') {
          meauText.value = t('meau.nav1')
        } else if (href.value == '/internet') {
          meauText.value = t('meau.nav6')
          meau_text.value = t('meau.nav6')
        } else if (href.value == '/gamefi') {
          meauText.value = t('meau.nav7')
          meau_text.value = t('meau.nav7')
        }   else if (href.value == '/node') {
          meauText.value = t('meau.nav4')
        } else if (href.value == '/nft') {
          meauText.value = t('meau.nav5')
          meau_text.value = t('meau.nav5')
        } else if (href.value == '/Protocol') {
          meauText.value = 'DeepLink Protocol'
        } else if (href.value == '/software') {
          meauText.value = 'DeepLink Software'
        } else if (href.value == '/blog') {
          meauText.value = 'Blog'
        }  
      }
      const showModel = (command) => {
        showObj.value = hrefObj[command]
        if (showObj.value.code) {
          dialogTitle.value = command
          dialogTableVisible.value = true
        } else {
          window.open(showObj.value.link, '_blank')
        }
      }
      const change = (el) => {
        ishover.value = el
      }
      const linkHref = (el) => {
        router.push({path: el})
        href.value = el
        localStorage.setItem('path', el)
        store.commit('changePath', el)
        console.log(el, 'el');
        if (el == '/') {
          meauText.value = t('meau.nav1')
        } else if (el == '/internet') {
          meauText.value = t('meau.nav6')
        } else if (el == '/gamefi') {
          meauText.value = t('meau.nav7')
        }   else if (el == '/node') {
          meauText.value = t('meau.nav4')
        } else if (el == '/nft') {
          meauText.value = t('meau.nav5')
        } else if (el == '/Protocol') {
          meauText.value = 'DeepLink Protocol'
        } else if (el == '/software') {
          meauText.value = 'DeepLink Software'
        } else if (el == '/blog') {
          meauText.value = 'Blog'
        }  
        drawer.value = false
      }
      const linkHref2 = (command) => {
        router.push({path: command})
        href.value = command
        localStorage.setItem('path', command)
        store.commit('changePath', command)
        if (command == '/internet') {
          meau_text.value = t('meau.nav6')
        } else if (command == '/gamefi') {
          meau_text.value = t('meau.nav7')
        } else if (command == '/nft') {
          meau_text.value = t('meau.nav5')
        } 
      }
      const drawer = ref(false)
      const drawer1 = ref(false)
      const drawer2 = ref(false)
      const linkHref1 = () => {
        drawer.value = true
      }
      const openDraw2 = () => {
        drawer.value = false
        drawer2.value = true
      }
      const isPc = () => {
        if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
      }
      const isphone = ref(false)
      onMounted(() => {
        text.value = lanObj[lan.value]
        href.value = store.state.path
        if (href.value == '/') {
          meauText.value = t('meau.nav1')
        } else if (href.value == '/internet') {
          meauText.value = t('meau.nav6')
          meau_text.value = t('meau.nav6')
        } else if (href.value == '/gamefi') {
          meauText.value = t('meau.nav7')
          meau_text.value = t('meau.nav7')
        }   else if (href.value == '/node') {
          meauText.value = t('meau.nav4')
        } else if (href.value == '/nft') {
          meauText.value = t('meau.nav5')
          meau_text.value = t('meau.nav5')
        } else if (href.value == '/Protocol') {
          meauText.value = 'DeepLink Protocol'
        } else if (href.value == '/software') {
          meauText.value = 'DeepLink Software'
        } else if (href.value == '/blog') {
          meauText.value = 'Blog'
        }  
        isphone.value = isPc()
        // if (lan.value == 'zh' && href.value == '/Protocol') {
        //   console.log(lan.value, href.value, 111)
        //   linkHref('/')
        //   meau_text.value = t('meau.nav1')
        // }
      });
      watch( route, (newValue) => {
        if (href.value == '/') {
          meauText.value = t('meau.nav1')
        } else if (href.value == '/internet') {
          meauText.value = t('meau.nav6')
          meau_text.value = t('meau.nav6')
        } else if (href.value == '/gamefi') {
          meauText.value = t('meau.nav7')
          meau_text.value = t('meau.nav7')
        }   else if (href.value == '/node') {
          meauText.value = t('meau.nav4')
        } else if (href.value == '/nft') {
          meauText.value = t('meau.nav5')
          meau_text.value = t('meau.nav5')
        } else if (href.value == '/Protocol') {
          meauText.value = 'DeepLink Protocol'
        } else if (href.value == '/software') {
          meauText.value = 'DeepLink Software'
        } else if (href.value == '/blog') {
          meauText.value = 'Blog'
        } 
        href.value = newValue.path
      })
      watch(lan, (newValue) => {
        // if (newValue == 'zh' && href.value == '/Protocol') {
        //   linkHref('/')
        //   meau_text.value = t('meau.nav1')
        // }
      })
      return {
        lan,
        text,
        meau_text,
        dialogTableVisible,
        dialogTitle,
        showObj,
        ishover,
        href,
        isphone,
        handleCommand,
        showModel,
        change,
        linkHref,
        drawer,
        linkHref1,
        linkHref2,
        meauText,
        drawer1,
        drawer2,
        openDraw2
      };
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  width: 100%;
  font-style: normal;
  font-family: Monda;
  .meau {
    width: 100%;
    height: 106px;
    background: #000;
    // background: #363F5E;
    .content {
      max-width: 1400px;
      width: 100%;
      height: 106px;
      margin: auto;
      display: flex;
      align-items: center;
      font-size: 16px;
      padding: 0 50px;
      line-height: 72px;
      font-style: normal;
      box-sizing: border-box;
      justify-content: space-between;
      color: rgba(255, 255, 255, 0.5);
      &.hide {
        display: flex;
      }
      &.show {
        display: none;
      }
      .logo {
        width: 200px;
        cursor: pointer;
      }
      .nav {
        display: flex;
        align-items: center;
        .nav1 {
          cursor: pointer;
          display: block;
          margin-right: 40px;
          text-decoration: none;
          color: rgba(255, 255, 255, 0.6);
          &.active {
            color: #FFFFFF;
            // font-weight: 600;
          }
          &:hover {
            color: #FFFFFF;
            // font-weight: 600;
          }
          .el-dropdown-link {
            font-size: 16px;
            &.active {
              color: #FFFFFF;
            }
            // color: rgba(255, 255, 255, 0.6);
            
          }
        }
        .nav_new {
          cursor: pointer;
          display: flex;
          font-weight: 500;
          margin-right: 50px;
          align-items: center;
          &:hover {
            color: #FFFFFF;
          }
          .el-dropdown {
            font-weight: 500;
            margin: 0 5px;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.6);
            &:hover {
              font-weight: 600;
              color: #FFFFFF;
            }
            &.active {
              color: #FFFFFF;
              font-weight: 600;
            }
          }
          .icon1 {
            width: 20px;
            height: 20px;
          }
          .icon2 {
            width: 10px;
            height: 6px;
          }
        }
      }
      .nav2 {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #FFFFFF;
        &:hover {
          color: #FFFFFF;
        }
        .el-dropdown {
          font-weight: 500;
          color: #FFFFFF;
          margin: 0 5px;
        }
        .icon1 {
          width: 20px;
          height: 20px;
        }
        .icon2 {
          width: 10px;
          height: 6px;
        }
      }
    }
  }
}

.lan_En.header {
  .meau {
    .content {
      padding: 0 120px;
      font-size: 14px;
      .nav  {
        .nav1 {
          margin-right: 20px;
        }
        .nav_new {
          margin-right: 20px;
        }
        .nav2 {
          margin-left: 0;
        }
      }
    }
  }
}

.detailsInfo {
  width: 100%;
  text-align: center;
  .img {
    width: 100%;
    display: flex;
    overflow: hidden;
    max-height: 350px;
    align-items: center;
    img {
      width: 100%;
    }
  }
  p {
    font-size: 14px;
    color: #1F1F1F;
    margin-top: 10px;
    a {
      text-decoration: none;
    }
  }
 
}

@media screen and (max-width: 1400px){
  .header {
    .meau {
      .content {
        padding: 0 30px;
      }
    }
  }
  .lan_En.header {
    .meau {
      .content {
        padding: 0 30px;
        font-size: 13px;
        .nav {
          .nav1 {
            margin-right: 15px;
          }
          .nav2 {
            margin-left: 0;
          }
          .nav_new {
            margin-right: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .header {
    min-width: 600px;
    .meau {
      height: 70px;
      .content {
        height: 70px;
        font-size: 20px;
        padding: 0 50px;
        color: rgba(255, 255, 255, 0.8);
        &.hide {
          display: none;
        }
        &.show {
          display: flex;
        }
        .logo {
          width: 60px;
          height: 36px;
        }
        .nav1 {
          cursor: pointer;
          text-decoration: none;
          color: rgba(255, 255, 255, 0.6);
          display: flex;
          align-items: center;
          .icon2 {
            margin-left: 5px;
          }
          &.active {
            color: #FFFFFF;
            font-weight: 600;
          }
          &:hover {
            color: #FFFFFF;
            font-weight: 600;
          }
          .el-dropdown-link {
            font-size: 16px;
            &.active {
              color: #FFFFFF;
            }
            // color: rgba(255, 255, 255, 0.6);
            
          }
        }
        .nav2 {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 18px;
          color: #FFFFFF;
          &:hover {
            color: #FFFFFF;
          }
          .el-dropdown {
            font-weight: 500;
            color: #FFFFFF;
            margin: 0 5px;
          }
          .icon1 {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          .icon2 {
            width: 10px;
            height: 6px;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .lan_En.header {
    .meau {
      .content {
        font-size: 20px;
      }
    }
  }
}

</style>

<style lang="scss">
.drawerInfo {
  height: initial !important;
  border-radius: 20px 20px 0 0;
  .el-drawer__body {
    padding: 0;
  }
  .meauInfo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .meaulist {
      width: 100%;
      cursor: pointer;
      padding: 20px 0;
      font-size: 34px;
      font-weight: 400;
      text-align: center;
      text-decoration: none;
      color: rgba(54, 63, 94, 0.6);
      &.active {
        font-weight: 600;
        color: #363F5E;
        background: rgba(54, 63, 94, 0.08);
      }
      &:hover {
        font-weight: 600;
        color: #363F5E;
        background: rgba(54, 63, 94, 0.08);
      }
    }
  }
  .iconInfo {
    width: 100%;
    padding: 50px 20px;
    background: #eee;
    .title {
      font-size: 34px;
      color: #363F5E;
      font-weight: 600;
      line-height: 48px;
      text-align: center;
      margin-bottom: 40px;
    }
    .community {
      width: 100%;
      .follow {
        width: 90%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .list {
          margin: 0 20px 10px;
          cursor: pointer;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: rgba(54, 63, 94, 0.6);
          p {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            img {
              width: 20px;
              margin: 0 5px;
            }
          }
          span {
            width: 80px;
            height: 80px;
            margin-bottom: 5px;
            display: inline-block;
            &.twitter {
              background: url(../assets/twitter.png) no-repeat 100%/100%;
            }
            &.telegram {
              background: url(../assets/telegram.png) no-repeat 100%/100%;
            }
            &.discord {
              background: url(../assets/discord.png) no-repeat 100%/100%;
            }
            &.tiktok {
              background: url(../assets/tiktok.png) no-repeat 100%/100%;
            }
            &.medium {
              background: url(../assets/medium.png) no-repeat 100%/100%;
            }
            &.youtube {
              background: url(../assets/youtube.png) no-repeat 100%/100%;
            }
            &.instagram {
              background: url(../assets/instagram.png) no-repeat 100%/100%;
            }
            &.facebook {
              background: url(../assets/facebook.png) no-repeat 100%/100%;
            }
          }
          &:hover {
            color: #363F5E;
            span {
              &.twitter {
                background: url(../assets/twitter1.png) no-repeat 100%/100%;
              }
              &.telegram {
                background: url(../assets/telegram1.png) no-repeat 100%/100%;
              }
              &.discord {
                background: url(../assets/discord1.png) no-repeat 100%/100%;
              }
              &.tiktok {
                background: url(../assets/tiktok1.png) no-repeat 100%/100%;
              }
              &.medium {
                background: url(../assets/medium1.png) no-repeat 100%/100%;
              }
              &.youtube {
                background: url(../assets/youtube1.png) no-repeat 100%/100%;
              }
              &.instagram {
                background: url(../assets/instagram1.png) no-repeat 100%/100%;
              }
              &.facebook {
                background: url(../assets/facebook1.png) no-repeat 100%/100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
