<template>
  <div class="blog" :class="{lan_En : lan != 'zh'}">
    <div class="page page_cont1">
      <div class="page_bg1"></div>
      <div class="page_bg2"></div>
      <div class="page_bg3"></div>
      <div class="page_cont">
        <div class="featured">Featured</div>
        <div class="cont_text"><a href="https://www.deeplink.cloud/" target="_blank">{{ atricle }}</a></div>
      </div>
    </div>
    <div class="page page_cont2">
      <div class="page_title title">Categories</div>
      <div class="page_cont">
        <div class="meau_li" :class="{active: choose == 'All'}" @click="chooseReport('All')">All</div>
        <div class="meau_li" :class="{active: choose == 'News'}" @click="chooseReport('News')">News</div>
        <div class="meau_li" :class="{active: choose == 'Development'}" @click="chooseReport('Development')">Development</div>
        <div class="meau_li" :class="{active: choose == 'Update'}" @click="chooseReport('Update')">Update</div>
        <div class="meau_li" :class="{active: choose == 'Product'}" @click="chooseReport('Product')">Product</div>
        <div class="meau_li" :class="{active: choose == 'Community'}" @click="chooseReport('Community')">Community</div>
      </div>
    </div>
    <div class="page page_cont3">
      <div v-if="show_report.length" class="page_cont">
        <div class="blog_cont animation_hide" v-animate="{delay: 0, class:'fadeInUp'}" v-for="(item, index) in show_report" :key="index" @click="showInfo(item)">
          <div class="top_img">
            <img :src="require(`@/assets/image_img/blog/${item.img}`)"/>
          </div>
          <div class="top_title">{{ item.title }}</div>
          <div class="top_text">{{ item.text }}</div>
        </div>
      </div>
      <div v-else class="nodata">
        Waiting for updates...
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref, computed, watch, onMounted } from "vue";
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'Blog',
    setup() {
      const { t, locale } = useI18n();
      const store = useStore()
      let lan = computed(() => {
        return store.state.lan
      })
      const choose = ref('All')
      const report = ref([
        {
          type: "News",
          img: 'blog1.png',
          title: 'DeepLink Protocol Successful Showcase at Token2049, Singapore',
          text: 'The SingaporeToken2049 Week was an event that brought together trailblazers, tech innovators, and blockchain enthusiasts from all over the world. As the leading decentralized cloud gaming and AI computing protocol, DeepLink Protocol presence at the event highlighted our continuous commitment to driving groundbreaking innovations in the Web3 space. Here’s an inside look into how DeepLink Protocol made its mark at Token2049 and showcased its technology to an eager global audience'
        },
        {
          type: "News",
          img: 'blog2.png',
          title: 'DeepLink Protocol 2024 Vision: Revolutionizing Tech and Global Connectivity.',
          text: 'As DeepLink Protocol, we are thrilled to unveil our visionary plans and goals for the upcoming year, charting a course toward Innovation, expansion, and community empowerment.'
        },
        {
          type: "Update",
          img: 'blog3.png',
          title: 'Transforming the Gaming Landscape with Blockchain-Powered Cloud Gaming',
          text: 'In the ever-evolving landscape of gaming, technological advancements continue to redefine the way we play, connect, and experience virtual worlds. Among the most trans-formative innovations to emerge is DeepLink Protocol, a pioneering platform that leverages blockchain technology to revolutionize cloud gaming. In this comprehensive exploration, we delve into the multifaceted dimensions of DeepLink Protocol, unraveling its disruptive potential, strategic vision, and profound implications for the future of gaming'
        },
        {
          type: "Product",
          img: 'blog4.png',
          title: 'DeepLink Protocol — A Game-Changing Cloud Gaming Handheld',
          text: 'The gaming industry is no stranger to innovation, and the latest player on the scene is DeepLink Protocol. This decentralized AI cloud gaming protocol aims to disrupt the market by offering an unparalleled gaming experience. Let’s dive into the details of this groundbreaking handheld device and explore how it stacks up against the Nintendo Switch.'
        },
        {
          type: "Update",
          img: 'blog5.png',
          title: 'DeepLink Protocol Launches Phase 2 of DLC Public Sale After a Successful Phase 1',
          text: 'DeepLink Protocol is excited to announce that Phase 2 of our public sale for DeepLink Coin (DLC) is now live! This follows the resounding success of Phase 1, where we hit our sale target of 1,050,000 USDT in just 48 hours. The overwhelming support and confidence from our community and investors have been truly inspiring.'
        },
        {
          type: "Development",
          img: 'blog6.png',
          title: 'DeepLink Software Reaches Over 1 million Users — The Future of Cloud Gaming Begins Here',
          text: 'We are thrilled to announce that DeepLink Protocol Software has surpassed an incredible milestone, reaching over 1 million users! This achievement is a testament to our dedication to delivering unparalleled cloud gaming experiences and decentralized AI solutions.'
        },
        {
          type: "Update",
          img: 'blog7.png',
          title: 'DeepLink Protocol’s announcement on airdrop for holders of DBC Tokens on the DeepBrain Chan AI blockchain.',
          text: 'DeepLink Protocol is thrilled to unveil an exclusive airdrop event for holders of DBC Tokens on the DeepBrain Chain AI blockchain. This initiative offers a unique chance to earn DLC Tokens and deepen your engagement with our growing ecosystem. Here’s everything you need to know:'
        },
        {
          type: "Development",
          img: 'blog8.png',
          title: 'DeepLink Progress Report #18 | Aug.01 ~ Aug.31 (2024)',
          text: 'Dear DeepLink community members, please see below for the latest update on the project. The progress update is as of Aug 1st to Aug 31st '
        }
      ])
      const atricle = ref('DeepLink Protocol Successful Showcase at Token2049, Singapore')
      const show_report = ref([])
      const chooseReport = (type) => {
        choose.value = type
        if (type == 'All') {
          show_report.value = report.value
        } else {
          show_report.value = report.value.filter(el => el.type == type)
        }
      }
      const showInfo = (el) => {
        console.log(el);
      }
      onMounted(() => {
        show_report.value = report.value
      })
      return {
        lan,
        choose,
        atricle,
        show_report,
        chooseReport,
        showInfo
      };
    }
  })
</script>

<style lang="scss" scoped>
.blog {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  .page_title {
    color: #fff;
    line-height: 1.1;
    font-weight: 600;
    font-family: Monda;
  }
  .page_text {
    line-height: 1.1;
    font-family: Outfit;
    color: rgba(255, 255, 255, .5);
  }
  .gradient_text {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #8000FF 37%, #00FFD1 60%); 
  }
  .page {
    position: relative;
    z-index: 10;
  }
  .page_cont1 {
    width: 100%;
    height: 520px;
    min-width: 600px;
    overflow: hidden;
    max-width: 1200px;
    margin: 100px auto;
    position: relative;
    border-radius: 36px;
    background: #000403;
    border: 1px solid rgba(255, 255, 255, .2);
    .page_bg1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 690px;
      height: 517px;
      background: url(../assets/image_img/blog_page_bg1.png) no-repeat 100%/100%;
    }
    .page_bg2 {
      position: absolute;
      z-index: 1; 
      bottom: 0;
      right: 0;
      width: 478px;
      height: 437px;
      opacity: 0.3;
      background: url(../assets/image_img/blog_page_bg2.png) no-repeat 100%/100%;
    }
    .page_bg3 {
      position: absolute;
      z-index: 2;
      bottom: 0;
      right: 0;
      width: 940px;
      height: 650px;
      background: url(../assets/image_img/blog_page_bg3.png) no-repeat 100%/100%;
    }
    .page_cont {
      position: relative;
      z-index: 5;
      width: 100%;
      height: 100%;
      .featured {
        color: #03FF91;
        font-size: 24px;
        margin: 27px 30px;
        padding: 10px 30px;
        font-family: Outfit;
        border-radius: 14px;
        display: inline-block;
        background: rgba(255, 255, 255, .1)
      }
      .cont_text {
        position:absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 50px;
        font-size: 48px;
        font-weight: 600;
        border-radius: 28px;
        font-family: Outfit;
        box-sizing: border-box;
        background: rgba(255, 255, 255, .1);
        a {
          color: #fff;
          text-decoration: none;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 3; //行数
          line-clamp: 3;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
        }
      }
    }
  }
  .page_cont2 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    min-width: 600px;
    max-width: 1200px;
    text-align:center;
    position: relative;
    padding: 25px 50px;
    align-items: center;
    border-radius: 19px;
    margin: 0 auto 100px;
    box-sizing:  border-box;
    background: rgba(255, 255, 255, .1);
    .title {
      font-size: 36px;
      margin-right: 30px;
    }
    .page_cont {
      flex: 1;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      min-width: 600px;
      align-items: center;
      .meau_li {
        padding: 20px;
        font-size: 16px;
        cursor: pointer;
        font-family: Monda;
        transition: ease 1s;
        box-sizing: border-box;
        color: rgba(255, 255, 255, .5);
        border-bottom: 1px solid transparent;
        &:hover {
          color: #fff;
          border-bottom: 1px solid #07F2D4;
        }
        &.active {
          color: #fff;
          border-bottom: 1px solid #07F2D4;
        }
      }
    }
  }
  .page_cont3 {
    width: 100%;
    overflow: hidden;
    min-width: 600px;
    max-width: 1200px;
    position: relative;
    margin: 0 auto 100px;
    box-sizing:  border-box;
    .page_cont {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .blog_cont {
        width: 32%;
        padding: 15px;
        cursor: pointer;
        margin-right: 2%;
        border-radius: 33px;
        margin-bottom: 40px;
        box-sizing:  border-box;
        background: rgba(255, 255, 255, .05);
        border: 1px solid rgba(255, 255, 255, .1);
        &:nth-child(3n) {
          margin-right: 0;
        }
        .top_img {
          width: 100%;
          height: 162px;
          display: flex;
          overflow: hidden;
          border-radius: 33px;
          align-items: center;
          margin-bottom: 25px;
          justify-content: center;
          background: url(../assets/image_img/blog_page2_bg.png) no-repeat 100%/100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .top_title {
          width: 100%;
          color: #fff;
          font-size: 24px;
          font-weight: 600;
          line-height: 30px;
          font-family: Outfit;
          margin-bottom: 15px;
          text-decoration: none;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 3; //行数
          line-clamp: 3;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
        }
        .top_text {
          width: 100%;
          font-size: 16px;
          line-height: 30px;
          font-family: Outfit;
          text-decoration: none;
          color: rgba(255, 255, 255, .5);
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 5; //行数
          line-clamp: 5;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
        }
      }
    }
    .nodata {
      color: #fff;
      height: 500px;
      display: flex;
      font-size: 36px;
      font-weight: 600;
      font-family: Outfit;
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 900px) {
  .blog {
    .page_cont2  {
      .page_cont  {
        .meau_li {
          padding: 20px 10px;
        }
      }
    }
    .page_cont3 {
      .page_cont {
        .blog_cont {
          width: 48%;
        }
      }
    }
  }
  
}

</style>

