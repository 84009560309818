
export default {
  meau: {
    nav1: '홈페이지',
    nav2: '커뮤니티',
    nav3: '고객측 다운로드',
    nav4: '노드 보상',
    nav5: 'NFT',
    nav6: '클라우드 PC',
    nav7: 'GameFi',
  },
  cont1: {
    text1: '게임에 특화된 원격 제어 프로그램',
    text2: '클라우드 게임 플랫폼',
    btn: '지금 무료로 다운로드',
    version: '버전 {ver}',
    text3_1: '',
    text3_2: '커뮤니티에',
    text3_3: '가입 후 DeepLink Coin 보상을 받으세요'
  },
  cont2: {
    text1: 'DeepLink',
    text2: 'e스포츠급의 저지연 기술로 게임을 즐기세요!',
    text3: '핵심능력 및 기술장점',
    text4: '클라우드 게임 핵심능력 기반,전통 원격 제어를 훨씬 뛰어 넘는 체험 ',
    leaf1: {
      text1: '높은 유동성 및 성능',
      text2: '저렴한 원가 ',
      text3: '세계 최고 수준의 인코딩 및 디코딩 솔루션, 동일한 비트 전송률 성능 소비 및 리소스 소비 최소화',
      text4: 'peer-to-peer:  ipv6지원，최대 95% NAT통과 속도,제로 대역폭 사용 '
    },
    leaf2: {
      text1: '높은 프레임 속도, 짧은 대기 시간',
      text2: '',
      text3: 'LAN 딜레이는 15ms 미만이며 ,4K 240Hz의 성능을 보유, WAN 평가 세계 최초 진행 '
    },
    leaf3: {
      text1: '고화질',
      text2: '',
      text3: '스마트 알고리즘은 동일한 비트 전송률에서 시각적 효과를 개선하고 가장 현실 색상으로  복원합니다.'
    },
    leaf4: {
      text1: '높은 안정성',
      text2: '',
      text3: '대량 데이터가 지원하는 패킷 손실 방지 및 혼잡 방지 전략으로 패킷 손실 및 혼잡을 신속하게 조정할 수 있습니다.'
    },
    leaf5: {
      text1: '높은 보안',
      text2: '',
      text3: '모든 데이터는 AES256을 통해 DTLS1.2 암호화됩니다'
    }
  },
  cont3: {
    text1: '로컬 장치 제어하는 ​것처럼 매끄럽게',
    text2: '딥링크는 세 가지 주요 분야와 수십 가지 최적화 기술을 적용하여 패킷 손실률 5% 및 네트워크 지연 100ms 조건에서도 여전히 높은 프레임 속도를 유지하고 원활하게 실행할 수 있습니다. 다양한 네트워크 환경의 요구사항 충족',
    li1: {
      text1: '약한 네트웍간 대결 기술',
      text2: 'FEC 패킷 손실 방지 전략',
      text3: '대역폭 예측',
      text4: 'SVC 코딩 전략'
    },
    li2: {
      text1: '최적화 기술 전송',
      text2: '포트 지문 예측',
      text3: 'IPV6지원',
      text4: 'UPNP',
      text5: '스마트 선택 '
    },
    li3: {
      text1: '처리기술 표시',
      text2: '프레임 속도 적응 전략',
      text3: '동적 화면 예측',
      text4: 'Jitter처리전략',
      text5: 'AI 저코드 HD 렌더링',
      text6: '동적가상 현실기'
    },
    desc3: '랜 < 5ms · 인터시티 네트워크 < 10ms · 도 간 네트워크 < 30ms'
  },
  cont4: {
    text1: '고화질 그래픽 품질 ',
    text2: '매우 선명한 해상도',
    text3: '10.7억종 컬러 지원',
    text4: '4:4:4 무손실 이미지손실',
    text5: 'DeepLink는 항상 더 엄격한 화질 지표를 채택하여 다른 전통적인 원격 기술의 훨씬 능가하는 이미지 체험을 실현합니다. 원격 이미지 손실은 인간의 눈으로 식별할 수 있는 한계값보다 낮습니다. 화면 표시 효과에 대한 크리에이티브 업계의 정확한 요구 사항을 전적으로 지원합니다.',
    text6: '*PSNR은 피크 신호대 잡음비를 말하며, 20dB 미만일 때 영상 왜곡 정도가 높고, 20-30dB일 때 영상 왜곡 정도가 평균, 30-40dB일 때 영상 왜곡 정도가 낮고 40dB보다 크면 영상 왜곡 정도가 극히 낮아 육안으로 감지할 수 없습니다. '
  },
  cont5: {
    text1: '풍부한 주변 장치 지원',
    text2: 'DeepLink는 크리에이티브 업계를 위한 태블릿, 핸들 등 전문 주변기기의 원격 지원을 제공하고 PS, Zbrush, Unity 등 크리에이티브 소프트웨어의 사용을 최적화하여 원격 상태와 로컬 상태의 크리에이티브 생산 도구 체험의 일관성을 확보합니다.'
  },
  cont6: {
    text1: '다중 보장 · 최소 작동',
    block1: {
      title1: '계정 보안',
      desc1: '다중 계정 보호 메커니즘, 안심하고 사용하세요',
      title2: '연결 보안',
      desc2: 'DTLS1.2 프로토콜 보호, 권한 있어야만 액세스 가능',
      title3: '데이터 보안',
      desc3: '256비트 AES 암호화, 전송의 전체 프로세스 기밀유지 ',
      title4: '설비 보안',
      desc4: '설정에 따라 해당 기기는 해당 사용자만 볼 수 있습니다'
    },
    block2: {
      title1: '다중 보안 보장',
      desc1: '모든 연결 보호',
      title2: '간편 사용',
      desc2: '간편 시작'
    },
    block3: {
      title1: '라이트 다운로드 ',
      desc1: 'DeepLink 고객측 약 10M',
      title2: '간편 스타트',
      desc2: '가이드 참조하여 구성 프로세스를 완료하십시오.',
      title3: '간편 사용',
      desc3: '권한 내 모든 장치를 마우스로 원격제어 가능 '
    }
  },
  cont7: {
    text1: '원격 제어 솔루션 제공에 전념',
    block1: {
      word1: '교육업계',
      word2: '게임업계',
      word3: '크리에이티브디자인업계',
      word4: '정보기술업계',
      word5: '출정영업원',
      word6: '분산된공동작업자',
      word7: 'IT운영 및 원격유지보수 ',
      word8: '개인유저및게이머'
    },
    block2: {
      text1: '여러분야의 원격제어시나리오 지원',
      text2: '여러분야기업의 실제요구사항 기반으로 기업이 효율적방식으로 비즈니스목표 달성할수있도록 지원합니다.',
      text3: '유연하고안전한 맞춤형 암호화전략제공',
      text4: '회사직원은 실제권한에따라 언제어디서나 회사리소스에 액세스할수있습니다.'
    },
    block3: {
      text1: '다양한 유저니즈 충족',
      text2: '내부직원을위해 사용할 것인지 여부',
      text3: '또는 외부고객, 공동작업자 및 개인유저에게도 사용할수있습니다.',
      text4: '당사의 원격제어기능은 주류플랫폼 및 장치에서 서비스제공 가능합니다 ',
      text5: '문제해결하는 또다른 간단하고 영리한방법을 제공합니다'
    }
  },
  cont9: {
    block1: {
      text1: '다중 보안',
      text2: '모든 연결 보호',
    },
    block2: { 
      title1: 'PC방 공유',
      text1_1: '256비트 AES 암호화',
      text1_2: '전체 전송 과정의 기밀성',
      title2: '보안 연결',
      text2_1: 'DTLS1.2 프로토콜 보호',
      text2_2: '권한이 있는 경우에만 액세스x',
      title3: '기기 안전',
      text3_1: '설정에 따라 허가된 사용자만 해당 기기 열람 가능',
      title4: '계정 보안',
      text4_1: '블록체인 기술의 다계층 계정 보호 메커니즘 기반 , 기기 정보는 중앙서버를 거치지 않고  P2P 암호화 전송진행',
    },
    block3: {
      text1: 'PC방 공유',
      text2: 'PC방 점주는 딥링크를 통해 기기를 임대하여 수익을 올릴 수 있으며, 사용자는 딥링크를 통해 집에서 약 50km 떨어진 곳에서 PC방 게임을 즐길 수 있습니다',
    },
  },
  cont10: {
    title: '딥링크는 클라우드 PC방 솔루션을 제공합니다',
    desc: '비용 절감과 무한 확장성을 통해 PC방의 수익성 극대화',
    block1: {
      title1: '기존 PC방의 4가지 주요 문제점',
      text1: '또 전기료 인상 ?',
      text1_1: '전기료 부담',
      text1_2: '정부 정책에 따라 인상되는 전기료',
      text2: '40시리즈 그래픽 카드를 PC방에 얼마나 도입될까?',
      text2_1: 'GPU/CPU 업그레이드 부담',
      text2_2: '신규  GPU출시 시즌마다 찾아오는 고가의 GPU업그레이드 구매 부담',
      text3: 'PC방 요금에 대한 소비자 인식도”요금현실화”의 걸림돌?',
      text3_1: '사용 요금 인식',
      text3_2: '천원 이하에 국한된PC 이용요금 인상을 위한 명분 필요.',
      text4: '외부 환경 요인에 어떻게 대처하고 있나요?',
      text4_1: 'PC가동률 저조',
      text4_2: '전염병,기후 등의 문제로 PC방 이용자 감소. 미가동 PC에 대한 처분 문제.',
    },
    block2: {
      title1: '프랜차이즈 클라우드 PC방',
      desc1: '클라우드 PC방의 강점: 창업비용 및 유지비용 절감, 추가 업그레이드 비용없이  PC방 점주에게 최적의 경제적 이점을 제공합니다.',
      text1_1: '약 61%의 비용 절감',
      text1_2: '초기 투자 비용 및 PC 구매 비용 절감 및 감소',
      text2_1: '초 저지연',
      text2_2: '고성능 컴퓨팅  제공으로 게임 반응 속도 향상',
      text3_1: '유지보수 비용 ‘0원’',
      text3_2: '데스크톱 관련  관리 비용 일체 없음',
      text4_1: '매출액 상승',
      text4_2: 'GPU사양별 이용금액 설정,모든 좌석 프리미엄존 가능.',
      text5_1: '전기료 절약',
      text5_2: '에너지절약. 탄소절감',
      text4: '외부 환경 요인에 어떻게 대처하고 있나요?',
      text6_1: 'PC 업그레이드 비용 ‘0원’',
      text6_2: 'GPU/CPU 업그레이드는 클라우드 센터에서 진행.',
    }
  },
  footer: {
    link: '자세히 알아보기',
    community: '커뮤니티 팔로우',
    tip: '설명：커뮤니티종류 Twitter、telegram、reddit、medium、discord、tiktok、YouTube'
  },
  reward: {
    title1: 'DeepLink 노드',
    desc1: '보상 받을 수 있는 기회를 획득: DeepLink 토큰, Crown NFT, 커뮤니티 거버넌스의 권한',
    title2: '제네시스 노드 NFT 받기',
    text1: '딥링크의 네트워크 유저가 많을수록 더 많은 컴퓨팅과 트래픽이 필요하며. 제네시스 노드는 딥링크 네트워크의 인프라를 제공하여 탈중앙화를 실현하며  딥링크가 더 빠르게 발전할 수 있도록 한다.',
    text2: '제네시스 노드 NFT를 구매하면 네트워크 기여도에 대한 보상을 받을 수 있다',
    text3: '여기에는 DeepLink 토큰, Crown NFT, 커뮤니티 거버넌스의 권한이 포함될 수 있다.',
    text4_1: '질문있으세요?',
    text4_2: '및',
    text4_3: '에서 만나요.',
    btntext: '바로구매',
    text5: '제네시스 노드 NFT가 1,000개 판매될 때마다 가격은 1,000 USD 증가',
    text6_1: '현재 NFT별 ',
    text6_2: '지원하는 노드수량',
    text7: '제네시스 노드NFT총수량',
    text8: '영원히추가발행없음 ',
    text9: '초기가격',
    text10_1: 'NFT별지원',
    text10_2: '노드최대수량',
    text11: '최고가격',
    text12: '제네시스 노드란?',
    text13: '제네시스 노드는 DeepLink 네트워크 컴퓨팅 및 트래픽 전달의 핵심 작업을 수행한다.DeepLink는 마이닝 모델을 통해 더 많은 사람들이 참여하고 이익을 얻을 수 있다.',
    text14: '제네시스 노드에 총 몇 개의 토큰이 지급되나요?',
    text15: '총 코인 수의 30%, 300억개 딥링크 토큰',
    text16: '제네시스 노드 NFT란?',
    text17: '제네시스 노드 NFT를 가지고 있는 자만 채굴에 참여할 수 있다.',
    text18: '제네시스 노드 NFT판매 수익을 어떻게 분배하나요?',
    text19: '수입의 50%는 분산형 유동성 보상 풀(예: Uniswap, Sushiswap의 DeepLink Token/USDC)에 투자되고, 50%는 DeepLink 운영 회사의 수익입니다 ',
    text20: '구체적인 마이닝 메커니즘은 무엇인지요?',
    text21: '연간 총 50억 보상, 3년 동안 반감, DeepLink Crown NFT 수익의 10% 로 DeepLink 토큰 재구매,, 반감 전마다 총 보상 풀에 추가, 50% 토큰의 총 보상으로 투입, 3년 동안 모든 노드의 보상 진행.  예를 들어, 3년 후 DeepLink Crown NFT 수입의 10%가 120억 개의 DeepLink 토큰을 재구매하면  보상 풀의 나머지 총수는 120억 + 150억, 총 270억 토큰 ,두번쨰 3년부터 연간 총 보상은  270억*50%*1/3=45억이다. ',
    text22: '제네시스 노드 기기의 요구 사항은 무엇인지요?',
    text23: '각 기기는 이미 DeepBrain Chain 네트워크에 온체인 필요한. DeepBrain Chain은 분산형 고성능 컴퓨팅 네트워크이며. DeepBrain Chain의 기기는  안정성을 보장하기 위해 DBC를 스테이킹 했다. DeepBrain Chain에 대한 자세한 이해:',
    text24: '제네시스 노드 NFT 보유의 다른 이점은 무엇인지?',
    details: {
      text1: '1.일정 수의 제네시스 노드 NFT를 보유하면 한 국가의 DeepLink NFT 판매 총대리점 권한을 얻을 수 있다(국가의 인구 비율에 따라 3백만 명당 1개의 NFT 필요).',
      text2: '2.딥링크 Coin에어드랍',
      text3: '3.딥링크 인큐베이팅 게임 프로젝트 토큰 에어드랍',
      text4: '4.온라인 게임 권한에 투표',
      text5: '5.게임 NFT 구매 할인',
      text6: '6.클라우드 게임 저작권 및 기간 구매 할인',
      text7: '7.커뮤니티 거버넌스에 참여할 수 있는 기타 권한'
    },
    question: {
      text1_1: '제네시스 노드란?',
      text1_2: '제네시스 노드는 DeepLink 네트워크 컴퓨팅 및 트래픽 전달의 핵심 작업을 수행한다.DeepLink는 마이닝 모델을 통해 더 많은 사람들이 참여하고 이익을 얻을 수 있다.',
      text2_1: '제네시스 노드에 총 몇 개의 토큰이 지급되나요?',
      text2_2: '총 코인 수의 10%, 100억개 딥링크 토큰',
      text3_1: '제네시스 노드 NFT란?',
      text3_2: '제네시스 노드 NFT를 가지고 있는 자만 채굴에 참여할 수 있다.',
      text4_1: '제네시스 노드 NFT판매 수익을 어떻게 분배하나요?',
      text4_2: '수입의 50%는 분산형 유동성 보상 풀(예: Uniswap, Sushiswap의 DeepLink Token/USDC)에 투자되고, 50%는 DeepLink 운영 회사의 수익입니다 ',
      text5_1: '구체적인 마이닝 메커니즘은 무엇인지요?',
      text5_2: '연간 총 50억 보상, 3년 동안 반감, DeepLink Crown NFT 수익의 10% 로 DeepLink 토큰 재구매,, 반감 전마다 총 보상 풀에 추가, 50% 토큰의 총 보상으로 투입, 3년 동안 모든 노드의 보상 진행.  예를 들어, 3년 후 DeepLink Crown NFT 수입의 10%가 120억 개의 DeepLink 토큰을 재구매하면  보상 풀의 나머지 총수는 120억 + 150억, 총 270억 토큰 ,두번쨰 3년부터 연간 총 보상은  270억*50%*1/3=45억이다. ',
      text6_1: '제네시스 노드 기기의 요구 사항은 무엇인지요?',
      text6_2: '각 기기는 이미 DeepBrain Chain 네트워크에 온체인 필요한. DeepBrain Chain은 분산형 고성능 컴퓨팅 네트워크이며. DeepBrain Chain의 기기는  안정성을 보장하기 위해 DBC를 스테이킹 했다. DeepBrain Chain에 대한 자세한 이해:',
      text7_1: '제네시스 노드 NFT 보유의 다른 이점은 무엇인지?',
      text7_2: `1.일정 수의 제네시스 노드 NFT를 보유하면 한 국가의 DeepLink NFT 판매 총대리점 권한을 얻을 수 있다(국가의 인구 비율에 따라 3백만 명당 1개의 NFT 필요).2.딥링크 Coin에어드랍.3.딥링크 인큐베이팅 게임 프로젝트 토큰 에어드랍.4.온라인 게임 권한에 투표.
      5.게임 NFT 구매 할인.6.클라우드 게임 저작권 및 기간 구매 할인.7.커뮤니티 거버넌스에 참여할 수 있는 기타 권한`,
    }
  },
  nft: {
    cont: {
      title1: '크라운NFT',
      desc1: '해당NFT 보유로 DeepLink 소프트웨어의 모든 기능을 1년 동안 사용할 수 있습니다',
      desc2: 'NFT 구매 후 휴면 상태이며, NFT를 사용해야 할 경우 딥링크 소프트웨어에서 활성화할 수 있다, NFT가 활성화되면 ',
      desc3: '1년 사용기간 카운트다운 시작된다. 딥링크 소프트웨어 NFT 수익금의 10%는 노드 보상에 투입된다 ',
      // desc4: '수익의 40%는 유동성 보상 풀에 투자되고, 50%는 딥링크 운영 회사의 수익입니다. ',
      desc4: 'DLC 토큰의 가치를 높이기 위해 수익금의 40%가 소각됩니다.'
    },
    enabl: {
      title1: 'NFT 권한 부여',
      // desc1: 'DeepLink 소프트웨어의 모든 기능에는 1년 사용 권한 포함',
      desc1: 'DeepLink 소프트웨어의 고급 기능에 액세스할 수 있습니다.',
      desc2: 'DeepLink Coin 에어드랍',
      desc3: '딥링크 인큐베이티드 게임 프로젝트 토큰 에어드랍',
      desc4: '온라인 게임 권한  투표',
      desc5: '게임 NFT 구매 할인',
      desc6: '클라우드 게임 저작권 및 기간 구매 할인',
      desc7: '커뮤니티 거버넌스에 참여할 수 있는 기타 권한'
      
    },
    edition: {
      title1: '전문버전',
      title2: '팀 에디션',
      tip: '크라운NFT',
      btn: '바로구매',
      title3: '전문버전 VS  팀 에디션',
      desc: '구체적 기증 사용 권한 ',
      list: {
        li1_1: '내용',
        li1_2: '기본버전',
        li1_3: '전문버전',
        li1_4: '팀 에디션',
        li2_1: '최고의 화질',
        li3_1: '데이터센터전달 ',
        li3_2: '소량',
        li3_3: '대량',
        li4_1: '대역폭전달',
        li4_2: '최대4M',
        li4_3: '최대 50M',
        li4_4: '무제한',
        li5_1: '포트간 지연',
        li5_2: '최저<40ms',
        li5_3: '최저<20ms',
        li6_1: '동시에 원격 제어를 시작할 수 있는 장치의 수',
        li7_1: '기기 수',
        li8_1: '원격 데스크탑',
        li9_1: '원격 카메라',
        li10_1: '원격SSH',
        li11_1: '프라이버시 스크린',
        li12_1: '가상 디스플레이',
        li13_1: '게이밍 키보드와 마우스',
        li14_1: '멀티 스트림',
        li15_1: '444 트루 컬러',
        li16_1: '화면 녹화 화면 캡처',
        li17_1: '듀얼 스크린 투 듀얼 스크린',
        li18_1: '핸들',
        li19_1: '유저/기기 그룹 관리',
        li20_1: 'SSO 원클릭 로그인',
        li21_1: '통합 관리 권한',
        li22_1: '일괄 배포',
        li23_1: '직원 행위 감사',
        li24_1: '워터마크',
        li25_1: '가격',
        li26_1: '단일 시스템 데스크톱 세션',
        li27_1: '파일 전송',

        new_text1: '동일한 지갑 주소가 여러 기기에서 동시에 로그인되어 있으면, 기기 중 하나만 원격 제어를 시작할 수 있습니다.',
        new_text2: '기계는 원격으로 제어되는 창을 두 개만 열 수 있습니다',
      }
    }
  },
  internetCafe: {
    title1: '딥링크 클라우드 PC방 솔루션',
    block1: {
      text1: 'PC방 점주 하드웨어에 대한 투자비용 60% 이상 절감',
      text2: '투자금 회수 기간 40% 단축',
      text3: '영상 보기',
    },
    title2: '클라우드 PC방 NFT 가격',
    block2: {
      desc: '수익금의 40%는 DLC 투자자를 위해 DEX 거래소의 DLC 코인 풀에 다시 투입됩니다.',
      text1_1: '50대 기기 지원',
      text1_2: '1년에 3600 USDT',
      text2_1: '100대 기기 지원',
      text2_2: '1년에 7200 USDT',
      text3_1: '150대 기기 지원',
      text3_2: '1년에 10800 USDT',
      text4_1: '200대 기기 지원',
      text4_2: '1년에 14400 USDT',
    },
    title3: 'DeepLink는 전 세계 클라우드 PC방 에이전트를  모집합니다',
    block3: {
      text1_1: '권한',
      text1_2: '시장 가격보다 낮은 가격으로 PC방 NFT를 받으세요!',
      text2_1: '책임',
      text2_2: '에이전트 지역에서 클라우드 PC방 파트너를 찾고 있습니다,기존 PC방에 클라우드 PC방 서비스를 제공할 수 있습니다.',
      text3_1: '자격 평가 기준',
      text3_2: '해당 지역의 PC방 사업과 관련된 자원을 보유해야 하며, 지역 인구 수에 상응하는 양의 DLC 토큰을 보유해야 합니다.',
    },
    title4: '클라우드 PC방 Q&A',
    block4: {
      text1_1: '클라우드 PC방이란 무엇인가요?',
      text1_2: '클라우드 PC방이란 기존 PC방에 고사양 GPU가 아닌 모니터와 미니 PC만 구성한 후 네트워크를 통해 원격 GPU 서버에 연결하면 됩니다. 사용자는 PC방에서 다양한 종류의 GPU 사양을 선택할 수 있으며, PC방 점주는 게임 리소스를 관리할 필요가 없고, PC방은 사용자가 사용한 시간만큼의 비용을 지불하면 됩니다.',
      text2_1: '에이전트 되려면 어떻게 신청할 수 있나요?',
      text2_2: '에서 텔레그램에 가입해 문의할 수 있습니다.',
      text3_1: '에이전트에게는 어떤 혜택이 있나요?',
      text3_2: '에이전트는 해당 지역에서의 독점 권리를 가지며, 클라우드 플랫폼을 확장하여 PC방에 서비스를 제공함으로써 각 NFT의 가격 차액을 얻습니다.',
      text4_1: '에이전트는 딥링크와 공식 계약을 체결해야 하나요?',
      text4_2: '네, 공식 계약이 필요합니다.',
      text5_1: '클라우드 PC방 NFT 수익은 DLC 토큰 가격에 어떤 영향을 끼치나요?',
      text5_2: '클라우드 PC방 NFT 수익금 중 40%는 유니스왑(Uniswap)과 같은 탈중앙화 거래소에서 DLC-USDT로 연결되어 DLC 토큰 구매에 사용되고, 구매한 DLC는 소각됩니다.',
      text6_1: '클라우드 PC방 NFT 수익이 DLC 토큰 구매에 투명하게 증빙될 수 있는 방법은?',
      text6_2: '발행된 클라우드 PC방 NFT의 개수는 온체인에서 확인할 수 있으며 발행 개수에 따라 NFT 수익을 계산하는데 사용할 수 있습니다.',
    },
    title5: '클라우드 PC방 파트너'
  },
  gameFi: {
    title1: '클라우드 GameFi 플랫폼',
    block1: {
      text1: '클라우드 GameFi 플랫폼',
      text1_1: '모든 사용자는 딥링크를 통해 게임 설치없이 GameFi 게임을 플레이할 수 있습니다.',
      text1_2: '모든 국가에서 게임 설치에 제한이 없으며, Apple 앱스토어에서 다운로드할 필요가 없습니다.',
      text1_3: 'GameFi 게임 제작사가 더 많은 사용자들에게 도달할 수 있도록 지원',
      text2: '게임 개발사 협력 방법',
      text2_1: '광고 협력',
      text2_2: '트래픽 협력',
      text2_3: '게임 분할',
      text2_4: 'NFT 협력',
    },
    title2: 'GameFi Q&A',
    block2: {
      text1_1: '클라우드 GameFi 플랫폼이란 무엇인가요?',
      text1_2: '딥링크는 양질의 게임 파트너를 선정하여 해당 게임을 딥링크 클라우드 플랫폼에 추가해 사용자가 게임을 설치하지 않고도 딥링크를 통해 바로 GameFi게임을 플레이할 수 있도록 합니다.',
      text2_1: '클라우드 GameFi 플랫폼은 모바일 게임을 지원하나요?',
      text2_2: 'PC에서 모바일 게임을 플레이할 수 있으며, 동시에 여러 게임 창을 열 수 있도록 지원합니다.',
      text3_1: 'GameFi 개발사가 딥링크와 협업하면 어떤 이점이 있나요?',
      text3_2: '딥링크는 파트너 개발사의 브랜드 홍보와 함께 더 많은 게이머를 유치할 수 있도록 지원합니다.',
      text4_1: 'GameFi 개발사는 딥링크와 공식 계약을 체결해야 하나요??',
      text4_2: '필요없습니다, 딥링크 자체적으로 양질의 파트너를 선별하여, 선별 과정에 통과된 게임들은 딥링크 플랫폼에 자동으로 등록됩니다',
      text5_1: '딥링크와 함께 협력하고 싶다면 어떻게 신청하나요?',
      text5_2: '신청 이메일:',
    }
  },
  homeNew: {
    cont1:  {
      text1: '원격 게이밍을 재정의 하다.',
      text2: '지연 없는 원격 제어, 딥링크에서는 현실입니다.',
      text3: '현재 윈도우 환경에서만 이용 가능합니다.'
    },
    cont2: {
      title: '원격 제어 소개',
      block1: {
        title: '원격제어란?',
        text: '떨어져 있는 다른 위치의 컴퓨터나 장치를 인터넷을 통해 제어하는 작업입니다.'
      },
      block2: {
        title: '원격제어의 유형',
        text: '원격제어는 클라이언트/서버 기반 원격제어와 P2P 기반 원격제어로 나뉩니다.'
      },
      block3: {
        title: '원격제어의 기능',
        text: '여러 컴퓨터간의 파일 공유, 원격 교육 보안 지원 등 다양한 기능을 수행할 수 있습니다.'
      },
      block4: {
        title1: '딥링크',
        title2: ' 원격제어의 장점',
        text: '블록체인 기술을 통한 분산 문제 해결을 통해 E스포츠급 초 저 지연 연결로 끊김 없는 최적의 게이밍 환경을 제공합니다.'
      }
    },
    cont3: {
      title: '단순 업무조차 어려웠던 기존 원격제어',
      text1: '연결 지연',
      text2: '잦은 끊김',
      text3: '프레임 드랍',
      text4: '비싼 사용료'
    },
    cont4: {
      title1: '이제는 로컬 장치를 제어하듯',
      title2: '매끄럽게!',
      desc: '스마트 컨트랙트 기술을 통한 분산 문제 해결로 빠르고 강력한 원격 제어를 경험해보세요.',
      text1: '간단하고 빠른 접속',
      text2: '8K 지원',
      text3: '고사향 게임 최적화',
      text4: '끊김, 지연X',
      text5: '업무 툴 최적화',
      text6: '무료 이용'
    },
    cont5: {
      title1: '강력하고 매끄러운',
      title2: '원격 게이밍',
      title3: '이제는 언제 어디서든 가능합니다',
      text1: '배틀필드',
      text2: '리그오브 레전드',
      text3: '발로란트'
    },
    cont6: {
      title1: '무겁고 비싼 게이밍 노트북',
      title2: '딥링크는',
      title3: '가볍고 경제적',
      title4: '입니다.',
      text1: '최소 260만원 이상',
      text2: '최소 1.6Kg 이상',
      text3: '고성능 노트북',
      text4: '0 원'
    },
    cont7: {
      title1: '여러 가지 방법으로',
      title2: '다양하게 활용이 가능합니다',
      block1: {
        text1: '외부',
        text2: '업무용',
        text3: '노트북'
      },
      block2: {
        text1: '사무실, 집',
        text2: '고사양',
        text3: '데스크탑',
        text4: '고사양 작업',
        text5: '고사양 게이밍'
      },
      block3: {
        text1: '고객사',
        text2: '공동 작업',
        text3: '원격 업무 수행'
      },
      title3: '강력한 기술력만큼',
      title4: '완벽한 보안을 지원합니다',
      block4: {
        text1: '계정 보안',
        text2: '블록체인 기술 기반의',
        text3: '다중 계정 보호 메커니즘'
      },
      block5: {
        text1: '연결 보안',
        text2: 'DTLS1.2 프로토콜 보호'
      },
      block6: {
        text1: '데이터 보안',
        text2: '256비트 AES 암호화'
      },
      block7: {
        text1: '설비 보안',
        text2: '사용자 권한 설정 가능'
      }
    },
    cont8: {
      title1: '딥링크로',
      title2: '어디서든 연결하세요',
      text1: '노드가 있는 곳이라면 어디서든 고사양 게임을 원격 제어로 끊김 없이 플레이 가능합니다. 국가, 장소에 상관없이 블록체인 노드 컴퓨터를 연결하여 고사양 온라인 게임을 즐길 수 있습니다',
      title3: '딥링크로 유연하게',
      title4: '협업하세요',
      text2: '딥링크의 초저지연 원격 연결을 통해 보다 강력한 협업이 가능합니다. 디자이너, 기획자, 개발자 모두에게 최적화되어 있습니다'
    },
    cont9: {
      title1: '게이밍 뿐만 아닙니다.',
      title2: '작업자를 위한 생산성 도구까지 지원합니다.'
    },
    cont10: {
      title1: '딥링크와 함께',
      title2: '공간적, 기술적 제약에서 해방되세요.',
      block1: {
        name: '조정현님',
        desc: 'IT운영 및 원격 유지 보수 담당자',
        text1: '지연이 없이 원격 업무 지원이 가능해',
        text2: '고객사 업무 만족도가 향상되었습니다'
      },
      block2: {
        name: '백상우님',
        desc: '게이머',
        text1: '이제는 영업용 노트북만 있어도  출장중에 고성능 게이밍이 가능합니다',
        text2: '상상도 못했던 일이죠'
      },
      block3: {
        name: '김준호님',
        desc: '영업담당자 ',
        text1: '기기 제약이 없어',
        text2: '노트북만 휴대해도 영업이 가능해졌습니다'
      },
      block4: {
        name: '유민경님',
        desc: '원격 근무 디자이너',
        text1: '언제 어디서든 다운로드 없이',
        text2: '본사 컴퓨터 접근을 통한 업무가 가능해졌어요!'
      },
    },
    cont11: {
      title: '딥링크 지갑(Wallet)이란',
      text1: '안전한 계정 보호 시스템',
      desc1: '딥링크 지갑은 블록체인 기술과 256비트 AES 암호화 및 디바이스 정보 기반 다층 계정 보호 메커니즘으로 강력하고 안전한 계정 보호 시스템을 지원합니다.',
      text2: '안전한 토큰 거래 및 활용',
      desc2: '지갑을 통해 스마트 컨트랙트, P2P 암호화 전송을 통한 거래보다 안전한 토큰 거래 및 활용이 가능합니다.',
      text3: '전 세계 어디서든 딥링크 사용 가능',
      desc3: '지갑 개인키만 있다면 전 세계 어디서든 개인 지갑에 로그인하여 딥링크를 통한 게임플레이가 가능합니다'
    },
    cont12: {
      title1: '초 저지연 원격 게이밍',
      title2: '딥링크로 지금 시작하세요!',
      text1_1: '딥링크 클라이언트',
      text1_2: '를 설치해주세요',
      text2_1: '딥링크 회원가입을 위한',
      text2_2: '개인 지갑을 생성',
      text2_3: '해주세요.',
      text3_1: '지갑 생성 후',
      text3_2: '원하는 기기에 접속하여 딥링크를 실행',
      text3_3: '합니다'
    }
  },
  cloudInternet: {
    title: '클라우드 인터넷 카페 솔루션 제공자로의 전환 방법',
    text: '현재 전 세계적으로 주요 인터넷 카페 분포 국가는 한국 (6,000), 중국 (40,000), 브라질 (10,000), 인도네시아 (20,000), 필리핀 (20,000), 베트남 (15,000), 터키 (5,000) 등입니다. 또한, 인도, 멕시코, 중동, 남아메리카, 아프리카, 유럽 등의 국가도 있습니다. 전 세계적으로 15 만 개 이상의 인터넷 카페가 있습니다. 그러나 인터넷 카페 산업은 20 년의 역사를 가지고 전통 산업이 되었습니다. 회수 기간은 3-4 년으로 증가하고 다양한 위험에 직면하고 있습니다. 예를 들어 COVID-19 팬데믹으로 많은 인터넷 카페가 폐쇄되었습니다. 따라서 인터넷 카페 산업은 회수 기간을 단축하고 위험을 감소시키며 운영 효율을 향상시킬 새로운 솔루션이 필요합니다. 이렇게하여 클라우드 인터넷 카페 솔루션이 탄생합니다.',
    cont2: {
      text1: '무엇',
      text2: '클라우드 PC방 솔루션?',
      text3: '클라우드 사이버 카페 솔루션은 초저지연 렌더링 기술을 갖춘 새로운 유형의 사이버 카페 서비스 모델입니다. 클라우드 사이버 카페에서는 게임 및 응용 프로그램이 로컬 컴퓨터에 직접 설치되지 않고 클라우드 GPU 서버에 호스팅됩니다. 사용자는 고성능 로컬 하드웨어가 필요 없이 클라우드 서비스를 통해 게임에 액세스하고 플레이할 수 있습니다.',
      block1: {
        text1: '로컬 하드웨어 요구 사항 줄이기',
        text2: '게임 및 응용 프로그램이 클라우드 GPU 서버에서 실행되므로 로컬 컴퓨터에 고성능 GPU 또는 대용량 저장 공간이 필요하지 않습니다. 이는 사이버 카페 하드웨어 투자 및 유지 관리 비용을 줄입니다.'
      },
      block2: {
        text1: '최신 게임 즉시 액세스',
        text2: '사용자는 다운로드 및 설치를 기다릴 필요없이 최신 게임에 즉시 액세스할 수 있습니다. 모든 게임 업데이트 및 유지 관리는 클라우드에서 이루어지므로 사용자에게 편리하고 효율적인 경험을 제공합니다.'
      },
      block3: {
        text1: '고성능 게임 경험',
        text2: '로컬 모니터는 미니 PC (약 100 달러)로 구성되어 있으며 클라우드 사이버 카페에서도 고성능 게임 경험을 제공할 수 있습니다. 이는 게임 계산이 원격 고성능 GPU 서버에서 이루어지기 때문입니다.'
      },
      block4: {
        text1: '공간 및 에너지 절약',
        text2: '낮은 구성의 로컬 컴퓨터는 더 적은 공간을 차지하며 더 적은 전력을 소비하여 사이버 카페 운영 비용을 절감하는 데 도움이 됩니다.'
      },
      block5: {
        text1: '쉬운 관리 및 유지 관리',
        text2: '사이버 카페 관리자는 모든 콘텐츠가 클라우드에서 중앙 집중식으로 관리되기 때문에 소프트웨어 및 게임을보다 쉽게 관리할 수 있습니다. 이로 인해 로컬 유지 관리 작업 및 잠재적 인 기술 문제가 줄어듭니다.'
      }
    },
    cont3: {
      text1: '한국의 Glowsteam 클라우드 사이버 카페',
      text2: '클라우드 사이버 카페의 실제 운영 환경'
    },
    cont4: {
      text1: '클라우드 사이버 카페 솔루션은 사이버 카페 투자자를 돕는 데 도움이 될 수 있습니다',
      text2: '순이익을 3배로 늘리고 투자 회수 기간을 40% 단축합니다',
      text3: '비교 데이터를 다운로드하려면 클릭하세요'
    },  
    cont5: {
      text1: '클라우드 사이버 카페 솔루션 제공업체의 수익은 어떻게 발생하나요?',
      text2: '수익이 사이버 카페 기기 당 수입의 12%로 계산되면, 총 1000 개의 사이버 카페에 10 만 개의 기기를 서비스하고, 각 기기가 하루 평균 10 시간 사용되며, 기기 당 시간당 평균 비용이 0.8 달러 인 경우 12%는 약 0.1 달러입니다.',
      text3: '클라우드 사이버 카페 솔루션 제공업체의 연간 수익은',
      text4: '100,000 * 10 * 0.1 * 365 = 3,650 만 달러',
      text5: '클라우드 사이버 카페 솔루션 제공업체가 되는 방법',
      text6: 'DeepBrainChain 및 DeepLink 기술을 기반으로 한 한국의 HYCONS가 완전한 클라우드 사이버 카페 솔루션을 개발했습니다. DeepBrainChain은 GPU 컴퓨팅 파워를 제공하고, DeepLink는 초저 지연 렌더링 기술을 제공하며, HYCONS는 전체 솔루션을 제공합니다. 유사하게, DeepBrainChain은 엔진을 제공하고, DeepLink는 자율 주행 시스템을 제공하며, HYCONS는 완전한 차량 솔루션을 제공합니다. 다양한 국가의 로컬 솔루션 제공 업체는 로컬 결제 시스템을 HYCONS에 통합하기만 하면 현지 사이버 카페에 서비스를 제공할 수 있으며, 솔루션 제공 업체는 자체 브랜드를 설정할 수 있습니다. HYCONS는 브랜드를 노출하지 않고 순수한 기술 서비스 제공자로만 기능합니다.',
      text7: '자세한 정보는 문의하십시오 :',
      text8: "service{'@'}deepbrainchain.org"
    },
    cont6: {
      title: '클라우드 사이버 카페 관련 자료 다운로드',
      text1: 'HYCONS 소개',
      text2: '클라우드 사이버 카페 솔루션 수익 분석',
      text3: '클라우드 사이버 카페 데이터 센터 아키텍처 다이어그램',
      text4: '클라우드 사이버 카페 솔루션 테스트 및 설치 매뉴얼',
      text5: 'DeepLink 프로토콜 소개'
    }
  },
  reward2: {
    desc: {
      title1: 'DeepLink 노드',
      text1: '노드 소유자만 CPU 트래픽 채굴 및 개인 GPU 기기 채굴에 참여할 수 있습니다. 총 보상은 DLC 토큰의 12%이며, 그 중 4%는 CPU 트래픽 노드, 8%는 개인 GPU 노드에 할당됩니다. 토큰 총 수량은 120억입니다. 각 노드의 출력은 실제로 판매된 노드 수와 관련이 있습니다. 모두 판매될 경우 각 노드는 10만 DLC를 생산하며, 노드의 10%만 판매될 경우 각 노드는 100만 DLC를 생산합니다.',
      title2: '총 노드 수: 120,000 추가 발행 없음',
      text2: '각 노드마다 추가 에어드롭 제공',
      text3: '각 노드에서 채굴되는 최소 DLC 수량',
      text4: '초기 가격',
      text5: '최고 가격'
    },
    btn_text: '노드 구매',
    table: {
      tier: '계층',
      license: '노드 라이센스 가격 (USDT)',
      total_num: '총 라이선스',
      total_usdt: '총 USDT',
      buy: '구매'
    },
    intro: {
      title: '노드 마이닝 소개',
      text1_1: 'DeepLink의 네트워크 사용자가 많을수록 더 많은 네트워크 트래픽과 계산 능력이 필요하며, 이를 위해 DeepLink 네트워크에 인프라를 제공하기 위해 더 많은 CPU 트래픽 머신과 GPU 계산 머신이 필요합니다. 이를 통해 분산화가 이루어지고 DeepLink의 성장이 더욱 빨라집니다. 노드 권한을 구매하면 마이닝에 참여하여 보상을 받을 기회를 얻을 수 있습니다. 질문이 있으신가요? 위에서',
      text1_2: '와',
      text1_3: '함께 대화하세요.',
      text2: '각 노드는 NFT 형태로 발행되고, 추가로 BSC 체인이 있는 30,000 DLC의 에어드랍을 받습니다. 에어드랍은 거래소 상장 전에 시작되며, 에어드랍 DLC의 20%는 즉시 잠금 해제되고 80%는 8개월 동안 잠금 해제됩니다. \n ( DLC 토큰 프로세스 보기: 지갑을 열고 BSC 네트워크를 선택하고, 토큰 추가를 선택하고 계약 주소를 입력합니다: 0xD610Be30b06A3828A27D608a38F1E85faAFdEDcC )',
      text3: '노드는 2024년 10월 31일 이전에 타인에게 재판매할 수 없습니다.',
      text4: '채굴된 토큰의 25%는 즉시 잠금 해제되며, 나머지 75%는 150일에 걸쳐 선형적으로 잠금 해제됩니다.',
      text5: '상장 후 10일 이내에 마이닝이 시작되지 않을 경우, 보상은 에어드롭을 통해 배포되며, 에어드롭은 7일마다 발생합니다. 에어드롭 규칙은 마이닝 출력 규칙과 동일하며, 하루 총 출력량은 410만 DLC로, 판매된 노드 수에 따라 균등하게 배분됩니다. 25%는 즉시 잠금 해제되고, 나머지 75%는 150일 동안 선형적으로 잠금 해제됩니다. 마이닝이 이미 시작된 경우 보상을 받으려면 해당 기계가 필요합니다.',
      text6: '20개 이상의 노드를 구매하는 경우 노드 운영을 위임할 수 있습니다. 개별적으로 1,000개 이상의 노드를 구매하는 투자자에게는 노드 운영을 위한 전체 지원이 제공되며, 노드 운영을 돕기 위해 협력 운영자와 도킹할 수 있는 기능도 제공됩니다.',
      text7: '하나의 가상 머신은 20개의 NFT 노드를 지원할 수 있으며, 수입은 20배로 증가합니다.'
    },
    question: {
      ques1: '총 몇 개의 노드 NFT가 판매됩니까?',
      ans1: '총 12만 개',
      ques2: '각 노드 NFT의 비용은 얼마입니까?',
      ans2: '10단계로 나누어져 있으며, 가격은 30U에서 155U까지 다양하며, 공식 웹사이트에서 판매되며 매진될 때까지 판매됩니다',
      ques3: '노드 NFT 구매는 일회성 결제입니까?',
      ans3: '예, 노드 구매는 일회성 결제이며, 이후 추가 비용은 발생하지 않습니다',
      ques4: '노드 NFT 구매 시 추가 에어드롭이 있습니까?',
      ans4: '예, 각 노드 소유자는 30,000DLC의 에어드롭을 받습니다. 에어드롭은 상장 전 거래소에 시작되며, DLC의 20%는 즉시 잠금 해제되며, 80%는 8개월 동안 잠금 해제됩니다',
      ques5: '구체적인 마이닝 메커니즘은 무엇입니까?',
      ans5: '총 보상은 120억 DLC입니다. 트래픽 노드의 총 수익은 40억 DLC이며, 개인 GPU 머신 노드의 총 수익은 80억 DLC입니다. 노드 NFT를 구매하면 이 두 가지 마이닝에 동시에 참여할 수 있습니다. 첫 4년간의 보상은 연간 15억 DLC이며, 4년마다 절반으로 줄어듭니다. 일일 총 보상은 411만 DLC이며, 노드 NFT 소유자에게만 분배됩니다. 채굴된 토큰의 25%는 즉시 잠금 해제되며, 나머지 75%는 150일 동안 선형적으로 잠금 해제됩니다',
      ques6: '구매한 노드 NFT가 CPU 트래픽 머신과 개인 GPU 머신 마이닝에 동시에 참여할 수 있습니까?',
      ans6: '예',
      ques7: 'CPU 대역폭 트래픽 머신 노드는 무엇입니까?',
      ans7: 'CPU 대역폭 트래픽 머신 노드는 DeepLink 네트워크의 계산 및 트래픽 전달의 핵심 역할을 담당합니다. 마이닝 모델을 통해 DeepLink는 더 많은 사람들이 참여하고 수익을 얻을 수 있도록 합니다. 트래픽 노드의 총 수익은 40억 DLC입니다',
      ques8: '첫 해의 대역폭 트래픽 머신에 대한 총 보상은 얼마입니까?',
      ans8: '첫 해 보상은 5억 DLC이며, 매일 약 137만 DLC가 보상됩니다',
      ques9: '각 CPU 대역폭 트래픽 머신 노드의 수익과 대역폭의 관계는?',
      ans9: '기본 대역폭은 10Mb 업링크 대역폭입니다. 대역폭 값은 10Mb 업링크 대역폭의 경우 10입니다. 대역폭은 10의 배수로만 유효하며, 예를 들어 45Mb 업링크 대역폭은 대역폭 값으로 40으로 계산됩니다. 대역폭은 실제 사용에 따라 30% 증가합니다. 마지막으로 실제 대역폭 값에 따라 수익이 나누어집니다',
      ques10: '대역폭 트래픽 머신 노드의 구성 요구 사항이 높습니까? 구성 정보가 있습니까? 마이닝에 참여하는 방법은 무엇입니까?',
      ans10: '구성 요구 사항이 높지 않습니다. vCPU 1C + 2G 메모리 + 50G 하드 디스크가 필요합니다. AWS에서 렌탈 비용은 약 10 USD/월이며, 대역폭은 실제 사용량에 따라 과금됩니다. AWS에서 CPU 가상 머신을 렌탈한 후 DeepLink 트래픽 노드 마이닝 프로그램을 설치하고, 지갑 주소를 생성하고, 노드 NFT를 지갑으로 전송한 후 마이닝 기능을 활성화하여 마이닝에 참여할 수 있습니다',
      ques11: '개인 GPU 머신 노드는 무엇입니까?',
      ans11: '개인은 자신의 GPU 컴퓨터를 제공하여 마이닝에 참여할 수 있습니다. GPU 머신은 DeepLink 프로토콜에 클라우드 게임 인프라 서비스를 제공합니다. 개인 GPU 머신 노드의 총 수익은 80억 DLC입니다',
      ques12: '첫 해 개인 GPU 머신 노드의 총 보상은 얼마입니까?',
      ans12: '첫 해 보상은 10억 DLC이며, 매일 약 274만 DLC가 보상됩니다',
      ques13: '개인 GPU 머신 노드의 구성 요구 사항은 무엇입니까? 마이닝에 참여하는 방법은?',
      ans13: 'GPU 머신 구성 요구 사항은 다음과 같습니다: CPU 주파수 최소 3.0GHz, GPU는 Nvidia Geforce 시리즈: 20xx, 30xx, 40xx, 메모리 최소 16G'
    }
  }
}