import { createRouter, createWebHistory } from 'vue-router'
import home from '../views/home.vue'
import Reward from '../views/reward.vue'
import Nft from '../views/nft.vue'
import interNet from '../views/internetCafe.vue'
import gameFi from '../views/gameFi.vue'
import question from '../views/question.vue'
import software from '../views/software.vue'
import blog from '../views/blog.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/node',
    name: 'node',
    component: Reward
  },
  {
    path: '/nft',
    name: 'nft',
    component: Nft
  },
  {
    path: '/internet',
    name: 'internet',
    component: interNet
  },
  {
    path: '/gamefi',
    name: 'gamefi',
    component: gameFi
  },
  {
    path: '/question',
    name: 'question',
    component: question
  },
  {
    path: '/software',
    name: 'software',
    component: software
  },
  // {
  //   path: '/blog',
  //   name: 'blog',
  //   component: blog
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
