export default {
  meau: {
    nav1: 'ホームページ',
    nav2: 'コミュニティ',
    nav3: 'ダウンロード',
    nav4: 'ノード報酬',
    nav5: 'NFT',
    nav6: 'クラウドネットカフェ',
    nav7: 'GameFi',
  },
  cont1: {
    text1: 'ゲームに特化した遠隔操作ソフトウェア',
    text2: 'クラウドゲームプラットフォーム',
    btn: '今すぐ無料でダウンロード',
    btn1: 'PC ダウンロード',
    btn2: 'WEBリモートコントロール',
    btn3: 'Android ダウンロード',
    btn4: 'Google Play',
    version: 'バージョン {ver}',
    text3_1: '加入',
    text3_2: 'コミュニティ',
    text3_3: 'DeepLinkコインの報酬を得られます'
  },
  cont2: {
    text1: 'DeepLink',
    text2: 'eスポーツレベルの低遅延技術機能を活用してゲームをスムーズにプレイ',
    text3: 'コア機能と技術的優位性',
    text4: 'クラウドゲーミングのコア機能に基づき、従来型のリモートコントロールをはるかに超える体験を提供します！',
    leaf1: {
      text1: '高いフレームレートとパフォーマンス',
      text2: '低いコスト',
      text3: '業界トップクラスのエンコードおよびデコード ソリューションは、同じビット レートで最小限のパフォーマンスとリソースしか消費しません',
      text4: 'ピアツーピア: ipv6をサポートし、最大95％のNATパススルー率、ゼロの帯域幅使用率を実現します'
    },
    leaf2: {
      text1: 'スムーズな動きと低遅延',
      text2: '',
      text3: 'LANレイテンシ<15ms、4K 240Hz対応、WANの評価も世界的にトップクラスであります'
    },
    leaf3: {
      text1: '高解像度',
      text2: '',
      text3: 'インテリジェントなアルゴリズムにより、同じビット レートで視覚効果が向上し、最もリアルな色のパフォーマンスが復元されます'
    },
    leaf4: {
      text1: '高い安定性',
      text2: '',
      text3: '大量のデータによってサポートされるパケットロス対策や輻輳対策は、パケットロスや輻輳に対して迅速に調整することができます'
    },
    leaf5: {
      text1: '高いセキュリティ',
      text2: '',
      text3: 'すべてのデータはAES256によるDTLS1.2で暗号化されています'
    }
  },
  cont3: {
    text1: 'まるでローカルデバイスを操作しているかのようなスムーズさ',
    text2: 'DeepLink は、3 つの主要な分野と数十の最適化テクノロジーを適用することで、5% のパケット損失率と 100ms のネットワーク遅延* が発生した場合でも、高いフレームレートでスムーズな動作を維持できます。 さまざまなネットワーク環境の使用要件に対応します',
    li1: {
      text1: '脆弱ネットワーク対策',
      text2: 'FECパケットロス対策',
      text3: '帯域予測',
      text4: 'SVC符号化戦略'
    },
    li2: {
      text1: '伝送最適化技術',
      text2: 'ポート指紋予測',
      text3: 'IPV6サポート',
      text4: 'UPNP',
      text5: 'インテリジェント・ルーティング'
    },
    li3: {
      text1: 'ディスプレイ処理技術',
      text2: 'フレームレート適応戦略',
      text3: '動画予測',
      text4: 'ジッター処理戦略',
      text5: 'AI低ビットレートHDレンダリング',
      text6: 'ダイナミックバーチャルリアライザー'
    },
    desc3: 'LAN < 5 ms · 都市間ネットワーク < 10 ms - 県間ネットワーク < 30 ms'
  },
  cont4: {
    text1: '超シャープなグラフィック品質',
    text2: '極めて鮮明な解像度',
    text3: '10億7000万色対応',
    text4: '4:4:4ロスレス画質',
    text5: 'DeepLinkは常に、より厳しい画質指標を採用し、他の伝統的な遠隔技術をはるかに凌ぐ画像体験を実現します。遠隔画像歪みは人間の目の認識限界よりも低く、画像表示効果に対するクリエイティブ業界の精密な要求を完全にサポートします',
    text6: '*PSNRとはPeak Signal to Noise Ratioのことで、映像の歪みが大きい場合は20dB以下、映像の歪みが平均的な場合は20～30dB、映像の歪みが小さい場合は30～40dB、映像の歪みが極端に小さく肉眼では検出できない場合は40dB以上となります'
  },
  cont5: {
    text1: '豊富な周辺機器を対応',
    text2: 'DeepLinkは、クリエイティブ業界向けのタブレットやコントローラーなどのプロフェッショナル向け周辺機器のリモート サポートを提供し、PS、Zbrush、Unity などのクリエイティブ ソフトウェアの使用に最適化されており、リモートおよびローカルの状態でのクリエイティブ制作ツールのエクスペリエンスの一貫性を確保します'
  },
  cont6: {
    text1: 'マルチプロテクション · シンプル操作',
    block1: {
      title1: 'アカウントのセキュリティ',
      desc1: '多層的なアカウント保護メカニズム、安心してご利用いただけます',
      title2: 'セキュアコネクション',
      desc2: 'DTLS1.2 プロトコル保護、許可された場合のみアクセス',
      title3: 'データセキュリティ',
      desc3: '256ビットAES暗号化 送信プロセス全体の機密性',
      title4: 'デバイスセキュリティ',
      desc4: 'あなたの設定に従って、対応する人だけが対応するデバイスを見ることができます'
    },
    block2: {
      title1: '複数のセキュリティ保証',
      desc1: 'あらゆる接続を保護します',
      title2: '使いやすい',
      desc2: '簡単スタート',
    },
    block3: {
      title1: 'ダウンロード軽量',
      desc1: 'DeepLink クライアントはわずか約 10M',
      title2: '簡単に始められます',
      desc2: '指示に従って構成プロセスを完了します',
      title3: '使いやすい',
      desc3: 'マウスを使用して、権限内のデバイスを遠隔操作します'
    }
  },
  cont7: {
    text1: '遠隔操作ソリューションの提供に専念',
    block1: {
      word1: '教育産業',
      word2: 'ゲーム業界',
      word3: 'クリエイティブデザイン業界',
      word4: '情報技術産業',
      word5: '出張中の営業スタッフ',
      word6: '世界中に散らばる協力者',
      word7: 'IT運用保守 リモート保守',
      word8: '個人ユーザーとゲーマー'
    },
    block2: {
      text1: 'さまざまな業界の遠隔操作シナリオをサポート',
      text2: 'さまざまな業界の企業の実際のニーズに基づいて、企業が省エネかつ効率的な方法でビジネス目標を達成できるよう支援します',
      text3: 'パーソナライズされたカスタマイズと暗号化戦略、柔軟性とセキュリティを提供します',
      text4: '会社の従業員は、実際の権限に基づいていつでもどこでも会社のリソースにアクセスできます'
    },
    block3: {
      text1: '幅広いユーザーニーズに対応',
      text2: '企業の内部従業員に使用することを選択するかどうかにかかわらず',
      text3: 'あるいは、外部の顧客、協力者、さらには個人的な使用も可能です',
      text4: '当社のリモート制御機能は、主流のプラットフォームや機器でサービスを提供できます',
      text5: '問題を解決する別のシンプルでスマートな方法を提供します'
    }
  },
  cont9: {
    block1: {
      text1: '複数のセキュリティ保証',
      text2: 'すべての接続を保護します',
    },
    block2: { 
      title1: 'ネットカフェシェアリング',
      text1_1: '256ビットAES暗号化',
      text1_2: '送信中の機密性',
      title2: '接続セキュリティ',
      text2_1: 'DTLS 1.2プロトコルによる保護',
      text2_2: '認証されたたアクセスできます',
      title3: 'デバイスセキュリティ',
      text3_1: 'あなたの設定に従って、対応する人だけが対応するデバイスを見ることができます',
      title4: 'アカウントセキュリティ',
      text4_1: 'ブロックチェーン技術に基づく多層アカウント保護メカニズム。デバイス情報は集中サーバーを経由せず、ポイントツーポイント暗号化で送信されます',
    },
    block3: {
      text1: 'ネットカフェシェアリング',
      text2: 'ネット カフェのオーナーは、DeepLink を通じてカフェのマシンをレンタルして収入を得ることができ、ユーザーは DeepLink を使用して、自宅で周囲 50 キロメートル離れた場所にあるインターネット カフェ ゲームをプレイすることができます',
    },
  },
  cont10: {
    title: 'DeepLink はクラウド インターネット カフェ ソリューションを提供します',
    desc: 'コスト削減と無制限の拡張性により、インターネット カフェの利益率を最大化します',
    block1: {
      title1: '既存のネットカフェの4つの大きな問題点',
      text1: '電気代また上がるの？',
      text1_1: '電気代負担',
      text1_2: '政府の方針に従って電気料金を値上げします',
      text2: 'RTX-40XXネットカフェはいくら導入できますか？',
      text2_1: 'GPU/CPU アップグレードの負担',
      text2_2: '新しい GPU がリリースされるたびに、高価な GPU アップグレードの購入という負担が生じます',
      text3: '消費者の支払いに対する認識を変えることはできるでしょうか?',
      text3_1: '有料の認識',
      text3_2: '1ドル限定の有償修正に対する意識を変えるには、現状を変える理由が必要です',
      text4: '外部環境要因にどう向き合いましか？',
      text4_1: 'ネットカフェのパソコン利用率が低下',
      text4_2: 'パンデミックや天候などの影響でネットカフェに行く利用者が減り、使わなくなったPCの処分が問題になっています',
    },
    block2: {
      title1: 'クラウドネットカフェチェーン',
      desc1: 'クラウド・ネットカフェの価値：追加のアップグレード費用をかけずに、スタートアップ コストとメンテナンス コストを削減し、インターネット カフェのオーナーに最適な経済的利益を提供します',
      text1_1: '61％のコスト削減',
      text1_2: '初期費用やPC購入費の削減・削減',
      text2_1: '超低遅延',
      text2_2: '高性能のコンピューティング能力を提供し、ゲームの応答速度を向上させます',
      text3_1: 'メンテナンスコスト「ゼロ」',
      text3_2: 'デスクトップ管理コストが不要',
      text4_1: '売上高の増加',
      text4_2: 'GPU機種ごとに支払い料金を設定でき、全席を「プレミアムゾーン」に設定できます',
      text5_1: '節電',
      text5_2: 'エネルギーを節約し、二酸化炭素排出量を削減します',
      text4: '外部環境の影響にどう立ち向かいますか？',
      text6_1: 'アップグレード料金「ゼロ」',
      text6_2: 'GPU/CPUのアップグレードはクラウドセンターで行われます',
    }
  },
  footer: {
    link: '詳細はこちら',
    community: 'コミュニティへの参加',
    tip: '注：コミュニティには、Twitter、telegram、reddit、medium、discord、tiktok、YouTubeが含まれます'
  },
  reward: {
    title1: 'DeepLinkノード',
    desc1: 'DeepLink トークン、クラウン NFT、コミュニティガバナンスの力などの報酬を受け取る機会があります',
    title2: 'ジェネシスノードNFTの取得',
    text1: 'ジェネシスノードは、DeepLinkネットワークが分散化し、DeepLinkがより速く成長するためのインフラストラクチャを提供します',
    text2: 'ジェネシスノードNFTを購入することで、ネットワークへの貢献に対する報酬を受け取る機会があります',
    text3: 'これには、DeepLinkトークン、クラウンNFT、コミュニティガバナンスの力などが含まれます',
    text4_1: '質問がありますか？ どうぞ ',
    text4_2: 'と',
    text4_3: '上記よりご連絡ください',
    btntext: '今すぐ購入',
    text5: 'ジェネシスノードNFTが1,000個販売されるごとに、価格は1,000ドル増加します',
    text6_1: '現在のNFT',
    text6_2: '対応ノード数',
    text7: 'ジェネシスノード NFTの総数',
    text8: '追加発行なし',
    text9: '初回価格',
    text10_1: 'すべてのNFTをサポート',
    text10_2: '最大ノード数',
    text11: '最大価格',
    text12: 'ジェネシスノードとは？',
    text13: 'ジェネシスノードは、DeepLinkのネットワークコンピューティングとトラフィック転送のコアタスクを引き受け、DeepLinkはマイニングモードを通じてより多くの人が収益を得ることに参加できるようにします。',
    text14: 'ジェネシスノードへの報酬として、合計何トークンが与えられますか？',
    text15: 'コイン総数の30％、300億DeepLinkトークンです。',
    text16: 'ジェネシスノードNFTとは',
    text17: 'ジェネシスノード NFTを所有している場合のみマイニングに参加できます',
    text18: 'ジェネシスノード NFTの売却収入はどのように分配されますか？',
    text19: '収益の 50% は分散型流動性報酬プール (例: Uniswap、Sushiswap の DeepLink トークン/USDC) に投資され、50% は DeepLink 運営会社の収益です',
    text20: '具体的なマイニングの仕組みは何ですか？',
    text21: '報酬総額は年間50億で、3年後には半減する予定で、DeepLink Crown NFT収益の10%はDeepLinkトークンの買い戻しに使用され、半減する前に報酬プールの総額に追加される。 50% のトークンの報酬は、3 年間で全員に付与されます。 たとえば、3 年後、DeepLink Crown NFT 収益の 10% が 120 億 DeepLink トークンの買い戻しに使用された場合、報酬プールの残りの合計は 120 億 + 150 億、合計 270 億になります。報酬は次の 3 年間に開始され、270 億 * 50% * 1/3 = 45 億となります',
    text22: 'ジェネシス・ノード・マシンの条件は何ですか？',
    text23: '各マシンはすでに DeepBrain Chain ネットワークに存在している必要があります。DeepBrain Chain は分散型高性能コンピューティング ネットワークです。DeepBrain Chain 内のマシンは、マシンの安定性を保証できる DBC を誓約しています。 DeepBrain Chain に関する詳細な知識:',
    text24: 'ジェネシスノード NFTを保有する他のメリットは何ですか？',
    details: {
      text1: '1.一定数のジェネシスノード NFTを保有することで、その国でDeepLink NFTを販売する総代理店権を得ることができる（その国の人口比率に応じて人口300万人につき1NFTが必要）；',
      text2: '2.DeepLinkコインのエアドロップ',
      text3: '3.DeepLinkインキュベーションゲームプロジェクトトークンのエアドロップ；',
      text4: '4.オンラインゲーム特典の投票；',
      text5: '5.ゲームNFT購入時の割引；',
      text6: '6.クラウドゲームの権利と時間の購入割引；',
      text7: '7.その他、コミュニティ・ガバナンスに参加するための権限'
    },
    question: {
      text1_1: 'ジェネシスノードとは？',
      text1_2: 'ジェネシスノードは、DeepLinkのネットワークコンピューティングとトラフィック転送のコアタスクを引き受け、DeepLinkはマイニングモードを通じてより多くの人が収益を得ることに参加できるようにします',
      text2_1: 'ジェネシスノードへの報酬として、合計何トークンが与えられますか？',
      text2_2: 'コイン総数の10％、100億DeepLinkトークンです',
      text3_1: 'ジェネシスノードNFTとは',
      text3_2: 'ジェネシスノード NFTを保有している場合のみマイニングに参加できます',
      text4_1: 'ジェネシスノード NFTの売却収入はどのように分配されますか？',
      text4_2: '収益の50%は分散型流動性報酬プール（Uniswap、SushiswapのDeepLink Token/USDCなど）に投資され、50%はDeepLink運営会社の収益となります',
      text5_1: '具体的なマイニングの仕組みは？?',
      text5_2: '1年間で合計50億の報酬は、3年間で半減し、DeepLinkクラウンNFT収益の10％がDeepLink Tokenを買い戻し、各半減の前に総報酬プールに追加され、総報酬50％のトークンとして、3年間ですべてのノードに報われる。例えば、3年後、120億のための10％買い戻しDeepLink TokenのDeepLinkクラウンNFTの収益は、残りの報酬プールの合計は次のようになります：120億+ 150億、270億の合計、27億* 50％* 1 / 3 = 45億の年間総報酬を開始するために2番目の3年間',
      text6_1: 'ジェネシス・ノード・マシンの条件は何ですか？',
      text6_2: '各マシンは、分散型高性能演算ネットワークであるDeepBrain Chainのネットワークに既に参加している必要があり、DeepBrain Chainのマシンは、マシンの安定性を保証するDBCを誓約しています。DeepBrain Chainについて詳しく理解します：',
      text7_1: 'ジェネシスノード NFTを保有するその他のメリットは何ですか？',
      text7_2: `1.一定数のジェネシスノード NFTを保有すると、その国でDeepLink NFTを販売する総代理店権を得ることができます（その国の人口比率に応じて、人口300万人ごとに1NFTが必要です）；2.ディープリンクコインのエアドロップ
      3.DeepLinkインキュベートゲームプロジェクトトークンのエアドロップ、4.オンラインゲーム特典の投票、5.ゲームNFT購入の割引、6.クラウドゲーム著作権および時間購入の割引、7.コミュニティガバナンスに参加できるその他の特典`,
    }
  },
  nft: {
    cont: {
      title1: 'クラウンNFT',
      desc1: 'このNFTを所有することで、DeepLinkソフトウェアの全機能を1年間利用することができます',
      desc2: 'NFTは購入後、休眠状態にあり、このNFTが必要になったときにDeepLinkソフトウェアでアクティベートすることができます',
      desc3: 'NFTが有効化されると、使用期限1年のカウントダウンが開始されます。 DeepLinkソフトウェアのNFTからの収益の10％がノード報酬に投資されます,',
      // desc4: '収益の40%は流動性報酬プールに投資され、50%はDeepLink運営会社の収益となります',
      desc4: '収益の 40% は DLC トークンの価値を高めるために破棄されます',
      desc1_1: 'このNFTを所有することで、DeepLinkソフトウェアの全機能を1年間利用できます',
      desc2_1: 'NFTは購入後、休眠状態となり、必要なときにDeepLinkソフトウェアでアクティベートすることができます。 NFTがアクティベートされると、1年間の使用期間のカウントダウンが開始されます',
      desc3_1: 'DeepLinkソフトウェアNFTの収益の10%はノード報酬に投資され、収益の40%は流動性報酬プールに投資され、50%はDeepLink運営会社の収益となります'
    },
    enabl: {
      title1: 'NFTのエンパワーメント',
      // desc1: 'DeepLink ソフトウェアのすべての機能には 1 年間のライセンスが付属しています',
      desc1: 'DeepLink ソフトウェアの高度な機能にアクセスできる',
      desc2: 'ディープリンクコインのエアドロップ',
      desc3: 'DeepLink がインキュベートしたゲーム プロジェクト トークンのエアドロップ',
      desc4: 'オンライン ゲームの許可に投票する',
      desc5: 'ゲームNFT購入割引',
      desc6: 'クラウド ゲームの著作権と期間購入の割引',
      desc7: 'コミュニティガバナンスに参加するためのその他の権限'
    },
    edition: {
      title1: 'プロフェッショナル版',
      title2: 'チーム版',
      tip: 'クラウンNFT',
      btn: '今すぐ購入',
      title3: 'プロフェッショナル版 VS チーム版',
      desc: '特定の機能の使用権',
      list: {
        li1_1: '内容',
        li1_2: '基本バージョン',
        li1_3: 'プロフェッショナル版',
        li1_4: 'チーム版',
        li2_1: '最高の画質',
        li3_1: 'リピータールーム',
        li3_2: '少量',
        li3_3: '少量',
        li4_1: '転送帯域幅',
        li4_2: '最大4M',
        li4_3: '最大50M',
        li4_4: '無制限',
        li5_1: 'エンドツーエンドの遅延',
        li5_2: '最小 <40ms',
        li5_3: '最小 <20ms',
        li6_1:'同時にリモートコントロールを開始できるデバイスの数',
        li7_1:'デバイスの数',
        li8_1:'リモートデスクトップ',
        li9_1:'リモートカメラ',
        li10_1:'リモートSSH',
        li11_1:'プライバシー画面',
        li12_1:'仮想ディスプレイ',
        li13_1:'ゲーミングキーボードとマウス',
        li14_1:'マルチストリーミング',
        li15_1:'444 トゥルーカラー',
        li16_1:'画面の録画とスクリーンショット',
        li17_1:'デュアルスクリーンからデュアルスクリーンへ',
        li18_1:'ハンドル',
        li19_1:'ユーザー/デバイスグループの管理',
        li20_1:'SSOシングルサインオン',
        li21_1:'一元管理権限',
        li22_1:'バッチ展開',
        li23_1:'従業員の行動監査',
        li24_1:'透かし',
        li25_1:'価格',
        li26_1: 'マシンごとのデスクトップ セッションの数',
        li27_1: 'ファイル転送',

        new_text1: '同じウォレットアドレスが複数のデバイスで同時にログインしている場合、デバイスのうち1つだけがリモートコントロールを開始できます',
        new_text2: '機械は遠隔操作できる窓を2つしか開けることができません',
      }
    }
  },
  internetCafe: {
    title1: 'Deeplink・クラウド・ネットカフェ・ソリューション',
    block1: {
      text1: 'ネットカフェオーナーのハードウェア投資を60%以上削減',
      text2: '投資回収サイクルを40%削減',
      text3: 'ビデオを見ます',
      text4: '資料ダウンロード',
    },
    title2: 'クラウドネットカフェNFTの価格',
    block2: {
      desc: '収益の40％をDEX取引所のDLCコインプールに投資し、DLC投資家に還元',
      text1_1: '50台のマシンをサポート',
      text1_2: '1年間3600 USDT',
      text2_1: '100台のマシンをサポート',
      text2_2: '1年間7200USDT',
      text3_1: '150台のマシンをサポート',
      text3_2: '1年間10800USDT',
      text4_1: '200台のマシンをサポート',
      text4_2: '1年間14400USDT',
    },
    title3: 'DeepLink、世界中でクラウドネットカフェの代理業者を募集中',
    block3: {
      text1_1: '権利',
      text1_2: 'クラウドネットカフェNFTを市場価格より安く入手します',
      text2_1: '責任',
      text2_2: 'ネットカフェにクラウドカフェサービスを提供できる、代理店地域のクラウドカフェパートナーを探します',
      text3_1: '応募資格',
      text3_2: 'エリア内にネットカフェ産業に関連するリソースがあり、エージェントエリアの人口に等しいDLCコインを保有する必要があります',
    },
    title4: 'クラウドネットカフェQ&A',
    block4: {
      text1_1: 'クラウドネットカフェとは？',
      text1_2: 'クラウドネット カフェでは、ハイエンド GPU を構成する必要がなく、ネット カフェでモニターとミニ PC を構成するだけで、ネットワーク経由でリモート GPU サーバーに接続できます。プレーヤーは、ネット カフェでさまざまなタイプの GPU サーバーを使用することを選択できます。また、インターネットカフェ側はゲームリソースを管理する必要がなく、利用時間に応じてプレイヤーに料金を支払うだけで済みます',
      text2_1: '代理店になるにはどうやって申し込みますか?',
      text2_2: 'テレグラムで私たちに連絡することができます：',
      text3_1: '代理店にはどのような収益がありますか？',
      text3_2: '代理店はその地域で独占的な権利を持ち、インターネットカフェにサービスを提供するクラウドプラットフォームを開発し、各NFTからの価格差を獲得します',
      text4_1: 'DeepLinkとの契約は必要ですか？',
      text4_2: 'はい、契約が必要です。',
      text5_1: 'クラウドカフェNFTの収益はDLCの価格にどのように影響しますか？',
      text5_2: 'クラウドカフェNFTの収益の40%は、Uniswapのような分散型取引所のDLC-USDTペアに置かれ、焼却されるDLCの購入に使用されます。',
      text6_1: 'クラウドカフェのNFT収益からのDLC購入の透明性はどのように確保されていますか？',
      text6_2: 'クラウドネットカフェNFTの発行数量をチェーン上で確認でき、発行数量により、NFTの収益を計算することができます',
    },
    title5: 'クラウドカフェパートナー'
  },
  gameFi: {
    title1: 'クラウドGameFiプラットフォーム',
    block1: {
      text1: 'クラウドGameFiプラットフォーム',
      text1_1: 'すべてのユーザーはダウンロードせずに DeepLink 経由で GameFi ゲームをプレイできます',
      text1_2: 'どの国でもゲームのダウンロードに制限はなく、Apple Appstoreからダウンロードする必要もありません',
      text1_3: 'GameFiゲームメーカーののユーザー拡大を支援します',
      text2: 'ゲームメーカー協力方法',
      text2_1: '広告協力',
      text2_2: 'トラフィック協力',
      text2_3: 'ゲームボーナス',
      text2_4: 'NFT連携',
    },
    title2: 'GameFi Q&A',
    block2: {
      text1_1: 'クラウドGameFiプラットフォームとは？',
      text1_2: 'DeepLinkが高品質のゲームパートナーを選定し、ゲームをDeepLinkクラウドプラットフォームに追加することで、ユーザーはゲームをダウンロードすることなく、DeepLinkを通じて直接GameFiゲームをプレイすることができます。',
      text2_1: 'クラウドGameFiプラットフォームはモバイルゲームをサポートしていますか？',
      text2_2: 'PC でのモバイル ゲームのプレイをサポートし、複数のゲーム ウィンドウを同時に開くことができます',
      text3_1: 'GameFiベンダーがDeepLinkと提携するメリットは？',
      text3_2: 'DeepLinkは、パートナーがブランドを露出し、より多くのゲーム プレーヤーを呼び込むのに役立ちます',
      text4_1: 'GameFiベンダーはDeepLink関係者と契約を結ぶ必要がありますか？',
      text4_2: '必要ありません。DeepLink は高品質のパートナーを審査し、審査に合格したゲームは自動的に DeepLink プラットフォームに掲載されます',
      text5_1: 'DeepLinkを提携の申し込み方法は？',
      text5_2: 'メールで申し込みます',
    }
  },
  lanchpad: {
    cont1: {
      title1: 'DeepLink Protocol',
      desc: '分散型 AI クラウド ゲーム プロトコル',
      title2: 'DeepLink Protocolにより、クラウド ゲーム開発がこれまでより簡単になります',
      btn1: 'PinkSaleへ',
      btn2: 'ホワイトペーパーをダウンロード',
      btn3: 'Pitch Deckのダウンロード',
      desc1: 'Deeplinkをサポートする DLCトークンを獲得しよう',
      desc2: '100%安全で検証済み',
      desc3: 'NVIDlA Inception Program'
    },
    cont2: {
      desc1: 'バトルフィールド',
      desc2: 'ディスプレイの効果は、例えば50キロメートルの2台のコンピュータであり、コンソールは普通のラップトップであり、コンソールはハイエンドのGPUマシンであり、ゲームの動的な画面を表示し、動画の形式は約3〜5秒です',
      btn: 'ビデオを再生して詳細を確認します'
    },
    cont3: {
      text1: '超低遅延',
      text2: '高解像度',
      text3: '超低遅延',
      text4: '遅延なし、切断なし、高い安定性',
      desc1: 'DeepLink Protocolは革新的な技術的進歩を達成し、比類のない完璧なエクスペリエンスをもたらします',
      desc2: 'Cisco、Intel、Nvidia、Huawei、Shunwang などのコア技術チームのメンバーが 2011 年からクラウド ゲーム業界に参入し、10 年以上の研究開発の蓄積に基づいて DeepLink が誕生しました'
    },
    cont16: {
      title1_1: 'DeepLink Protocol',
      title1_2: 'は、クラウド ゲーム向けに超低遅延の技術サービスを提供します',
      title2: '現在、DeepLink Protocolに基づいて開発されている製品には以下のものがあり、20 以上のアプリケーションが開発中です',
      text1: 'リモートコントロール',
      text2: 'クラウドゲーム＆クラウドゲームプラットフォーム',
      text3: 'クラウドネットカフェ',
      text4: 'クラウドXR',
      text5: 'XXX クラウドVRゲーム',
      text6: 'YYYクラウドゲームプラットフォーム'
    },
    cont4: {
      title1_1: 'DeepLink Protocol',
      title1_2: ' はトラフィック エントリとしてリモート コントロール ツールを使用します',
      title2: 'DeepLink ソフトウェアは、トラフィックの入り口としてリモート コントロール ツールを使用し、収益化の方法としてクラウド ゲーム プラットフォームとクラウド ネット カフェを使用します',
      text1_1: '600,000+',
      text1_2: 'MAU',
      text2_1: '110 +',
      text2_2: 'サービス提供国の数',
      text3_1: '1,400,000+',
      text3_2: 'ユーザー総数',
      text4_1: '200,000+',
      text4_2: 'DAU',
      desc1: 'リモートツール',
      desc2: 'クラウドゲーミング＆クラウドXR',
      desc3: 'クラウドインターネットカフェ',
      btn1: 'YouTube KOL ビデオレベル1',
      btn2: 'YouTube KOL ビデオレベル2',
      btn3: 'YouTube KOL ビデオレベル3',
      btn4: 'YouTube KOL ビデオレベル4',
    },
    cont17: {
      title1: 'DeepLink Protocol のビジネスモデル',
      text1: 'コミッションモデル',
      text2: 'ライセンスモデル',
      desc1: 'DeepLink Protocolは各クラウドゲーミング取引に対して20-30％の手数料を請求し、手数料はDLCで決済され、DLCの100％はリアルタイムで焼却されます',
      desc2: 'クラウドインターネットカフェおよびクラウドeスポーツホテルアプリケーションの場合、各アプリケーションおよび各コンピュータ端末ごとに月額6ドルのライセンス料を支払います。'
    },
    cont5: {
      title1: 'マイナーがブロックチェーン技術を通じてアイドル状態のネットワーク トラフィックを提供できるようにします',
      desc1: 'ノードアクセス',
      desc2_1: 'デバイス要件',
      desc2_2: 'トラフィック転送',
      desc3: '世界中のマイナーは、NFT Provider Traffic Nodesを購入することで、',
      desc3_2: 'DLCトークンの報酬を得ています',
      title2: 'トラフィックノードプロバイダー',
      text2: 'マイナーがトラフィックノードを提供', 
      title3: 'ユーザー端末',
      text3: '個人ユーザー'
    },
    cont6: {
      title1: 'DeepLinkの市場競争力を大幅に強化',
      title2: 'ブロックチェーン技術を通じて',
      desc1: 'スマート コントラクトを通じてデジタル通貨をステーキングすることで、GPU プロバイダーが安定したサービスを提供できるようになります',
      desc2: '誰もがネットワークに貢献し、GPU サーバーやトラフィック ノードの提供などの報酬を得ることができます',
      desc3: 'トラフィック転送ノードを提供するマイナーを導入することで、ノード数の幅広い分散を実現でき、中央集権的な企業がノードを配置するためのマシンに投資する必要がなく、より分散化され、企業はサーバーコストをゼロにできます',
      desc4: 'スマートコントラクトを通じてデジタル通貨をステーキングすることで、投資家は5～10年待つ必要がなく、約1年でリターンを得ることができます',
      desc5: 'ユーザーはプロジェクトの成長による配当に参加できるため、普及拡大につながります',
      desc6: 'ユーザーは国境なく自由に支払いが可能',
      text1: 'ステーキング',
      text2: '誰でも参加できます', 
      text3: 'コストゼロ',
      text4: '投資回収時間が短縮',
      text5: '国境なし', 
      text6: 'ボーナス'
    },
    cont7: {
      title1: 'Web2技術に対してWeb3技術による次元削減攻撃を行っています',
      desc1: 'クラウド ゲームで優れた低レイテンシ エクスペリエンスを実現するには、テクノロジーに加えて、GPU マシンもユーザーから 50 キロメートル以内にある必要があり、全世界をカバーするには 50,000 以上のデータ センターが必要',
      title2: '従来の中央集権型クラウドゲーミングプラットフォーム',
      title3: 'ブロックチェーン技術に基づくDeepLinkのクラウドゲーミングプラットフォーム',
      text1: 'プラットフォームは独自のマシンを購入しますが、それには莫大な費用がかかり、そのお金を取り戻す見込みはありません',
      text2: 'マシンのカバー範囲は狭く、全世界をカバーすることはできません', 
      text3: '中央集権的な技術ではでは数万のデータセンター接続をサポートできません',
      text4: 'マイナーやネットカフェのオーナーはGPUサーバーを提供し、マイナーにはトークン報酬があり、回収サイクルが速く、プラットフォームにはマシン購入のプレッシャーがありません。',
      text5: 'マイナーやネットカフェの数が多く、世界中に展開可能', 
      text6: '分散型ブロックチェーン テクノロジーは、100,000 を超えるデータ センターと 1,000 万を超える GPU サーバー ブック ポイントをサポートしています'
    },
    cont8: {
      title1: 'DeepLink トークンのアプリケーション・シナリオ',
      text1: 'DLCの購入',
      text2: 'DLCで支払い', 
      text3: 'USDTで支払い',
      text4: 'DLCで支払い',
      text5: 'DEXへDLCとUSDTを入金', 
      text6: 'DLCの販売',
      text7: 'ユーザー1',
      text8: 'DeepLinkソフトウェア', 
      text9: 'そのうち、DLCの40%はチェーン上で直接に焼却されます',
      // text10: '1.NFTの購入',
      // text11: '2.クラウドゲーム時間の購入', 
      // text12: '3.ネットカフェマシン時間のレンタル',
      // text13: '4.個人共有ゲーム時間の購入', 
      text10: '1.ライセンスを購入する',
      text11: '2.クラウドゲームの時間を購入する',
      text12: '3.インターネットカフェの機械をレンタルする期間',
      text13: '4.個人の共有ゲーム時間を購入する',
      text14: 'USDTの40%はDEXでDLCを買い戻され、その後焼却されます', 
      text15: 'NFTの購入',
      text16: 'DEXとCEX',
      text17: 'その他の取引プラットフォーム',
      text18: 'マイナー',
      text19: '流動性マイニング報酬',
      text20: '2年間報酬、年間50億DLC'
    },
    cont8_new: {
      text7: '個人ユーザー',
      text12: '3.インターネットカフェ機器のレンタル期間',
      text21: 'ビジネスユーザー',
      text22: 'ディープリンクプロトコル',
      // text23: '1.ライセンスを購入する',
      // text24: '2.10% から 20% の手数料',
      text23: '1.ライセンスを購入する',
      text24: '2.20-30%の手数料',
      text25: 'DLCの100%がチェーン上で直接破壊されます',
    },
    cont9: {
      title1: 'DeepLink トークン流動性マイニング報酬',
      text1: 'ユーザー1',
      text2: 'DEXへDLCとUSDTを入金', 
      text3: '流動性マイニング報酬',
      text4: '2年間報酬、年間50億DLC',
      text5: 'UniswapとPancake SwapへDLCとUSDTの流動性を注入', 
      text6: '報酬は1日1回支払われ、流動性NFTを通じて自動的に請求されます',
      text7: '報酬の80%は流動性プロバイダーに、20%はトレーダーに支払われます',
      text8: '1日あたり13,698,630 DLCの報酬が獲得できます'
    },
    cont9_new: {
      title1: 'DeepLink トークン流動性マイニング報酬',
      text1: 'DLC で 5% および USDT、ETH、BNB での同等価値',
      text2: 'DEX に注入される', 
      text3: 'DEX の流動性を向上させる',
      text4: '',
      text5: 'この5%のトークンはIDO（Initial DEX Offering）中に販売されます。実際の販売比率に基づいて、全体が最終的にDEXに注入されます', 
      text6: 'DLC および USDT の流動性をUniswap、SushiSwap、Pancake SwapなどのDEXに注入します。',
      text7: 'この流動性は5年間ロックされ、その後、プロジェクトの長期的な開発に使用されます。',
      text8: ''
    },
    cont10: {
      title: 'ロードマップ＆プラン',
      desc1: '2020年10月',
      text1: 'DeepLink製品の立ち上げ',
      desc2: '2021年3月',
      text2: 'シードラウンドの投資を受けました',
      desc3: '2021年8月',
      text3: 'DeepLink の最初の内部開発バージョンをリリースしました。技術的な遅延は 3ms に達し、ゲーム用キーボードとマウスをサポート',
      desc4: '2022年5月',
      text4: '3K 144HZ表示に対応したDeepLinkリモートコントロール機能テスト版第一弾をリリース',
      desc5: '2022年8月',
      text5: '仮想ディスプレイに対応したDeepLinkリモートコントロール機能のベータ版第2弾をリリース',
      desc6: '2022年10月',
      text6: '韓国語、中国語、英語に対応したDeepLink F版をリリース、シンガポールにDeepLink製品を運用するDeepCloudX会社を設立',
      desc7: '2022年12月',
      text7: 'エンジェルラウンド投資を受けました',
      desc8: '2023年4月',
      text8: '韓国No.1のネットカフェチェーンと協業し、クラウドネットカフェテストのためにネットカフェにリアル環境サンプルルームを構築、ユーザーから好評を得ました',
      desc9: '2023年6月',
      text9: 'DeepLinkが20万件ダウンロードを突破、100カ国のユーザーが8K 60HZ表示に対応',
      desc10: '2021年1月',
      text10: 'DeepLinkの研究開発の方向性を決定し、製品の設計・開発を開始',
      desc11: '2021年4月',
      text11: 'コア チームが結成され、コア チームのメンバーには、Cisco、IntelNvidia、Huawei、Shunwang などの企業の創設チームが含まれ、2011 年からクラウド ゲーム業界に参入しています',
      desc12: '2022年3月',
      text12: 'DeepLink、ネットカフェ分野でテスト開始、技術的な遅延は1msでした',
      desc13: '2022年7月',
      text13: 'DeepLinkリモートコントロール機能の第2弾ベータリリースを公開、プライバシー画面、リモートファイルコピーをサポート',
      desc14: '2022年9月',
      text14: 'マルチチャンネルストリーミングと444トゥルーカラーに対応したDeepLinkリモートコントロール機能テスト版第4弾をリリース',
      desc15: '2022年11月',
      text15: 'ソウルでクラウドネットカフェのテストを行い、ミニPC10台を設置し、ネットカフェのシミュレーション環境を表示します',
      desc16: '2023年2月',
      text16: 'DeepLinkが10万件ダウンロードを突破、50カ国のユーザーが4K 200HZディスプレイに対応',
      desc17: '2023年5月',
      text17: 'DeepLinkが15万件ダウンロードを突破、80カ国のユーザーが利用、DLCウォレット機能、ベトナム語、日本語に対応'
    },
    cont11: {
      title: '開発計画',
      desc1: '2023年7月',
      text1: 'デバイスリストの追加とシェア報酬機能をサポート',
      desc2: '2023年8月',
      // text2: '支持NFT功能，在首尔合作的第一家云网吧正式开始运营',
      text2: 'ソウル初のクラウドネットカフェをオープン',
      desc3: '2023年10月',
      // text3: '支持个人电脑和网吧电脑共享，目标DeepLink突破40万下载量',
      text3: 'シェア特典機能をサポート',
      desc4: '2023年12月',
      // text4: 'Gamefi平台上线，支持阿拉伯语、德语、法语、西班牙语、泰语、土耳其语',
      text4: 'NFT機能をサポート',
      desc5: '2024年Q1',
      text5: 'WEB版のゲームシェアリング機能をサポートし、DeepLinkのダウンロード数50万件以上を目標にパートナーシップを構築',
      desc6: '2024年Q2',
      text6: '10 のクラウド インターネット カフェで、DeepLink のダウンロード数が 100 万件を超えることを目標とします',
      desc7: '2024年Q3',
      text7: '3,000 台の共有コンピューターと 50 のクラウド インターネット カフェとの提携により、DeepLink のダウンロード数 200 万件以上を目標とする',
      desc8: '2024年Q4',
      text8: 'IOS、Mac、Vision Pro AR バージョンをサポートし、DeepLink のダウンロード数 300 万以上、共有コンピュータ 10,000 台、および 150 のクラウド インターネット カフェとの提携を目標としています',
      desc9: '2025',
      text9: '複数のブランドの VR および AR デバイスをサポートし、DeepLink のダウンロード数 600 万件以上、20,000 台の共有コンピューター、および 300 のクラウド インターネット カフェとのパートナーシップを達成することを目標としています',
      desc10: '2026',
      text10: 'DeepLink のダウンロード数は 1,500 万件以上、共有コンピュータは 50,000 台、クラウド インターネット カフェは 800 か所と提携するという目標を掲げています'
    },
    cont12: {
      text: '600億はDBCチェーンで発行され、300億はETHチェーンで発行され、100億はBSCで発行されます',
      text1: '資金調達',
      text1_1: 'シード・ラウンド',
      desc1_1: 'ロック解除は CEX 取引所に上場されてから 30 日後に始まり、20 か月に分割され、毎月 5% がロック解除されます',
      text1_2: 'エンジェルラウンド',
      desc1_2: 'ロック解除は CEX 取引所に上場されてから 30 日後に開始され、10 か月に分割され、毎月 10% がロック解除されます',
      text1_3: 'シリーズA',
      desc1_3: 'ロック解除は CEX 取引所に上場されてから 30 日後に開始され、10 か月に分割され、毎月 10% がロック解除されます',
      text1_4: 'プレセールと一般販売',
      desc1_4: '上場後に40%がロック解除され、残りは8月30日にロック解除が開始され、6か月のロック解除期間があり、その間毎月10%がロック解除されます。資金調達額の10〜30%がDEX流動性に追加されます(ロック済み) 3年間）。',
      text2: 'チーム',
      desc2: 'ロック解除は CEX 取引所に上場されてから 30 日後に始まり、20 か月に分割され、毎月 5% がロック解除されます',
      text3: 'コミュニティ・エアドロップ',
      desc3: '特定のタスクを満たした場合は、DID KYC 認証後にエアドロップを取得できます',
      text4: 'NFTの販売',
      desc4: 'NFT はソフトウェアのライセンス権をバインドし、トークンを付与し、CEX 取引所に上場された後、トークンのロックが解除され始めます',
      text5: 'マイニング',
      // desc5: '10% の流動性マイニング、5% の GPU コンピューティング能力を提供するマイナー、および 10% の帯域幅マイニングは 5 年ごとに半分になります。 NFT収入の10%がマイニング報酬に充てられます',
      desc5: '16% は GPU パワーを提供するマイナー向けで、4% は帯域幅マイニング向けで、4 年ごとに半分になります。 マイニングは、CEX 取引所に上場されてから 30 日後に開始されます。',
      text6: '財団',
      desc6: 'CEX取引所への上場後にロック解除が始まり、2年に分けて四半期ごとに12.5%がロック解除されます',
      text7: 'コンサルタントと法律',
      desc7: '複数の国の法的問題の解決とコンサルタントの報酬のために',

      text8: '合計',
      text9: '数量（十億）：',
      text10: '説明：',
      text11: '流通中（十億）：',
      text12: '非流通中（十億）：',
      text13: '1000億',
    },
    cont13: {
      title: 'チームとコンサルタント',

      intro1: 'DeepLink CEO',
      intro2: 'DeepLinkの共同創設者',
      intro3: 'DeepLink の最高執行責任者 (COO)',
      intro4: 'DeepLink CBO & インド市場リーダー',
      intro5: 'アドバイザー',
      intro6: 'アドバイザー',
      intro7: 'アドバイザー',
      intro8: '共同創設者兼CMO',
      user1_1: '13年間のAI経験を持つシリアル起業家であり、ブロックチェーンと分散コンピューティングネットワーク技術の開発に7年間携わっています。',
      user1_2: "2008年に中国海洋大学でコンピュータサイエンスの学士号を取得しました。",
      user1_3: "2012年にJushang Intelligentを設立し、世界初の中国製AI音声アシスタントである『Intelligent 360』を開発しました。",
      user1_4: "3年間でソフトウェアのダウンロード数は1700万を超えました。2014年には、世界初のAIスピーカーである『小知音』を発売しました。",
      user1_5: '2017年5月、彼はDeepBrainChainを設立し、数百のAI、クラウドゲーム、およびクラウドインターネットカフェの顧客にサービスを提供しています。',

      user2_1: 'シリアル起業家であり、シニアクラウドゲームの専門家。順望クラウドゲーム研究所の元所長で、技術的な研究と開発に責任を持っていました。',
      user2_2: "1999年に浙江大学でコンピュータサイエンスの学士号を取得。2001年から2004年まで、浙江蘭德で働き、通信関連の仕事に従事しました。",
      user2_3: "2009年、中国初のデュアルコアブラウザーである『Sunflower Browser』を創設しました。",
      user2_4: "2010年から2021年まで、上場企業である順望に参加し、順望クラウドコンピュータを設立し、順望研究所の所長を務めました。彼はクラウドインターネットカフェのソリューションを提供し、クラウドゲームを運営し、自社を中国で初めてクラウドゲームのソリューションを提供する企業にしました。",

      user3_1: '教育：2007年、北京大学、中国語学科',
      user3_2: "2023: DeepLink 最高執行責任者",
      user3_3: "2022年-2023年：DeepBrain Chainの最高責任者",
      user3_4: "2019年-2021年：CELLOGINの海外市場リーダーr",
      user3_5: "2018年：FLOW FACTORYのCEO",

      user4_1: '学歴と資格：- 2020年にミソール大学で取得した経営学士号。',
      user4_2: "- デジタルマーケティングの認定資格。",
      user4_3: "- SEOの認定資格。",
      user4_4: "- ブロックチェーンの認定資格。",
      user4_5: "テクニック建設における営業およびマーケティング：- 2019年における営業およびマーケティングの役割で、3か月間で50万ドルの売上を達成。",
      user4_6: "BYJU'Sのビジネス開発アソシエイト：- 2020年から2021年までの雇用。",
      user4_7:  "10億ドルで評価されるユニコーンスタートアップにおいて、成長を推進し、パートナーシップを築き、教育技術イニシアチブの運営を効率化する重要な役割を果たしました。",
      user4_8: "Space Innの共同創設者：- 戦略的思考、およびビジネスの洞察力を開発することに焦点を当てた独立プロジェクトの共同創設者。",
      user4_9: "株式市場の投資/取引：- 金融市場を航行し、投資機会を活用する専門知識を示す、5年の経験を積みました。",
      user4_10: "大手企業でのインターンシップ：- 企業文化、効率的なプロセス、および効果的なビジネス戦略に関する貴重な知見を得ました。",
      user4_11: "暗号通貨業界での経験：- 貴重な業界経験を積みました。",
      user4_12: "Empowaという有名なリアルFi  Cardanoプロジェクトに貢献し、その開発と成長を推進しました。Empowaは2022年のCardanoプロジェクトであり、その成長に貢献しました。",
      user4_13: "現在はDeepLink Cloudで働いており、暗号通貨分野での専門知識を拡大しています。",

      user5_1: '2012年から、IoTおよび通信サービス企業であるHyunjin ICTの副社長を務め、11年間。',
      user5_2: "営業およびマーケティング管理、公共機関での複数の大規模プロジェクトの経験。",
      user5_3: "韓国最大のIT企業であるSamsung SDSにおいて、情報戦略コンサルティング、ビジネスイノベーションプロジェクト、およびソフトウェア開発を14年間（1998年〜2012年）行う。",
      user5_4: "2017年から2019年まで、韓国で1位、世界で10位にランクインした仮想通貨取引所であるCoinzestの戦略企画エグゼクティブとして3年間勤務。",
      user5_5: "大手韓国企業および公共機関向けの福祉ショッピングモールを運営するHyunjin Venusの創設者であり、5年間ビジネスを運営。",
      user5_6: 'PMP（プロジェクトマネジメントプロフェッショナル）およびCISA（認定情報システム監査人）の資格を取得。',

      user6_1: '学歴：2003年、北京大学光華管理学院',
      user6_2: "2023年：MetABCのCEO",
      user6_3: "2020年：HillstoneHubのCEO",
      user6_4: "2019年：Hillstone Business CenterのCEO",
      user6_5: "2019年：Hillstone Global（ブロックチェーン）のCOO",
      user6_6: "2017年：Foundersbridgeの創設者",
      user6_7: "2017年：Hillstone Partners（プライベートエクイティ）のパートナー",
      user6_8: "2014年：BrightA Consultingの共同創設者",
      user6_9: "2012年：シニアコンサルタント（中国ビジネスの専門家）",

      user7_1: "ジョセフ・アレクサンダーは、ChatDBC.comの創設者であり、Deep Brain Chainのカスタム大規模言語モデルを手がけています.",
      user7_2: '彼の専門はDeepLinkとDBCの北米ビジネス開発です.' ,
      user7_3: "DeepLinkとDBCのすべてのニュースを知るために、Twitterで{'@'}ChatDBCをフォローすることができます.",
      
      user8_1: "Maryna Barysheva は、受賞歴のある Web3 マーケティング代理店である LKI Consulting の COO であり、ディープ テクノロジー、DeFi、インフラストラクチャ、ゲーム プロジェクトを専門としています。",
      user8_2: "彼女は、23 か国以上のブロックチェーン プロジェクトの規模拡大に成功し、10 万ドルから 1,000 万ドルのマーケティング予算を管理し、価値 1 億ドルの B2B テクノロジー スタートアップのマーケティングを運営してきました。",
      user8_3: "Maryna の専門知識は、コミュニティの成長、ブランド認知度、グローバル GTM 戦略にあります。",
      user8_4: "彼女は、世界中の暗号カンファレンスに頻繁に出演する国際講演者であり、ヘルシンキ大学の AI、テクノロジー、ブロックチェーン プロジェクトのインキュベーター メンターでもあります。",
    
      user9_1: "ブランドは暗号通貨のベテランです。2016年に暗号通貨の調査を開始し、有名な2017年の強気相場につながりました。",
      user9_2: "2012年にBigger Than Race™️を設立し、2017年に現在の形にリブランドしました。",
      user9_3: "資格には、メタバースの革新と開発のベンチャーファンドでありリーダーであるEveryRealmによるWeb3/メタバースの認定、IBMによるブロックチェーンとビットコインの認定などがあります。",
      user9_4: "ブランド「クリプト ウィザード」マーフィー",
      user9_4: "彼の主なビジョンと目標は、ブロックチェーン技術を通じて人々が経済的に自由になり、第4次および第5次産業革命の新しい経済のインフラストラクチャに投資して所有できるようにすることです。BTRコミュニティVCは、世界中の忠実なメンバーが明日のテクノロジーを所有できるよう支援しています。",
      user9_5: "2024年に、世界中のゲーマーに平等な競争の場を提供するAIクラウドゲームテクノロジーであるDeepLink Protocolの共同創設者兼CMOになりました。彼が故郷と呼ぶのはカリフォルニア州ロサンゼルスです。",
      
      user10_1: "サマーは、暗号プロジェクト、投資戦略などを扱うメディア企業、FireHustle の創設者兼ホストです。サマーはビジネスを学んだ経験があり、放送と通信の経験があります。",
      user10_2: "メディア面では、資産ポートフォリオには、44,000 人以上の登録者を持つ YouTube チャンネル、3,000 人を超えるメンバーからなる専用の Discord コミュニティ、プライベート メンバーシップ グループなどが含まれています。",
      user10_3: "投資面では、FireHustle は 50 社以上の Web3 スタートアップに個人的に投資しており、多くの暗号プロジェクト、VC 企業、ローンチパッド、シンジケート、KOL グループなどのマーケティング、資金調達、アドバイザリー パートナーとして活躍しています。",
    
      user11_1: "マニーはブロックチェーンの熱狂的支持者であり、業界で 10 年以上の経験を持つ専門家で、2013 年から大手企業やイニシアチブに貢献しています。",
      user11_2: "経歴: Grayscale の基礎職、Coinbase の運用チーム、DYDX および Ledger のマーケティング チーム、そして最後に Binance US での役職。",
      user11_3: "学歴: 米国の名門機関、特にスタンフォード大学でブロックチェーンと暗号通貨関連のコースを修了。「ブロックチェーンと暗号通貨: 包括的な入門」、「高度なブロックチェーン戦略」、「最高経営責任者プログラム」、「最高マーケティング責任者プログラム」など。専攻は財務および経営管理。",
    },
    cont14: {
      title: '私たちの投資組織',

      organ1: 'DeepBrain Chain Foundationは2017年に設立されました。2018年1月にはDBCトークンが火币交易所に上場されました。メインネットワークは2021年に開始されます。DeepBrain ChainはGPUを中核とした高性能分散コンピューティングパワーネットワークです。そのビジョンは、ブロックチェーン技術に基づき、無制限の拡張性を持つ分散型の高性能コンピューティングパワーネットワークを構築し、AI+メタバース時代における最も重要なコンピューティングパワーインフラになることです。主に人工知能、クラウドゲーム、映画レンダリングなどの分野にGPUコンピューティングサービスを提供しています。現在、数百の人工知能およびクラウドゲーム関連企業、3万人以上のAI実践者にサービスを提供しています。',
      organ2: '戈壁创投は、上海、北京、東南アジアにオフィスを構える専門のベンチャーキャピタルファームです。彼らは中国のテクノロジー初期段階のプロジェクトに投資を特化しています。戈壁ファンドの戦略的投資家にはIBM、シエラ・ベンチャーズ、マクローヒル・カンパニーズ、スチームボート・ベンチャーズ（ディズニーのベンチャーキャピタル部門）などが含まれています。彼らは以前に途牛旅行網、Camera360、烛雲などの有名なスタートアップに投資しています。戈壁创投は業界で確立された投資ファンドです。',
      organ3: 'Hyconsは、Hyunjin ICTが開発したDBC技術に基づくGPUコンピューティングクラウドプラットフォームです。Hyconsは、DBCベースの分散型高性能ネットワーク技術とDeepLinkベースの遅延のないHDストリーミング技術を備えています。このプラットフォームは、企業、学校、研究機関、フランチャイズなどのさまざまなサービスタイプに対する会員管理、登録、および支払いなど包括的なサービスを提供しています。現在、韓国では1,000枚以上のGPUカードと2つのセンターが稼働しており、将来的には5つのセンターに拡大する予定です。さらに、Hyconsはインターネットカフェのフランチャイズビジネスに適用することでテストを行っています。先進的な技術基盤、競争力のあるクラウドサービス料金、自動化されたサービス機能を備えたHyconsは、世界をリードするGPUコンピューティングプラットフォームを目指しています。',
      organ4: 'ROCK Capitalは、2018年に韓国で設立された専門の暗号通貨ファンド会社であり、ファイナンスと投資、ブロックチェーンと分散化の専門家を結集しています。様々な国内外の企業、グローバルプロジェクト、政府、機関、およびその他の経済主体との幅広い協力を通じて、ROCKは100以上のプロジェクトを成功裏に実行し、将来のファイナンスの多様な分野を開拓してきました。強化された協力、成長、活性化、およびパートナーシップを通じて、ROCKは多様な市場環境で豊富な経験と専門知識を持っています。ROCKは、岩のように揺るぎない原則を貫き、高いリターンを生み出し、投資機会を拡大し、資本活動を最大化し、具体的な顧客体験を創造することを目指しています。',
      organ5: '第 4 および第 5 の革命を推進する新興テクノロジーに焦点を当てた分散型ブロックチェーン。 VC は、あらゆる分野のさまざまな存在から得た豊富な知識を持っており、Web3 領域およびそれを超えた領域全体でチャンスを掴むのに役立ちます。 彼らは、Bloktopia、Animoca Brands、Stripe などの有名な企業と並行して投資しており、singularityNet や Shiba エコシステム、さまざまなゲーム スタジオとの関係を確保しています。 彼らのVCは草の根であり、BTR-Armyパスを持っている人がアクセスできます。'
    },
    cont15: {
      title: 'コミュニティイベント',
      name1: 'MARVELSベトナムホーチミンDeepLink',
      name2: 'Web3ベトナム イベントプレゼンテーション',
      name3: 'Deeplink香港のイベント',
      name4: 'IIITB インドの大学 DeepLink',
      name5: 'IIT スリシティ インド大学 DeepLink',
      name6: 'IITティルパティ インドの大学DeepLink',
      name7: 'NIT ワランガル インド大学 DeepLink',
      name8: 'DeepLink シンガポールのイベント',
      name9: 'DeepLink シンガポールのイベント',
      name10: 'ベトナム ESPORTS3.0 イベント',
      name11: 'DEEPLINK 韓国ソウル投資家デー',
      name12: 'DEEPLINK 東京投資家の日',

      name13: 'DEEPLINK in the TEAMZ/WEB3 SUMMIT, Tokyo Japan',
      name14: 'DEEPLINK Sponsors the HTX Dubai Whale Night',
      name15: 'DEEPLINK at the Lbank Dubai Night',
      name16: 'DEEPLINK Sponsors the HOT Crypto Twist with MTCAPITAL and Tido Capital',
      name17: "DEEPLINK and Animoca's Dubai Game Night",
      name18: 'DeepLink at the Token2049 Dubai afterparty',
      name19: "DEEPLINK's presentation at BYBIT event",
      name20: "DeepLink participated in the 2024 Tokyo Blockchain Expo",
      name21: 'Brando, Advisor to DeepLink, Presents Keynote Address at Depin House Consensus Conference Hosted by IOTX',
      name22: "DeepLink Principal Consultant is attending the Investor Conference Forum in Austin, USA",
      name23: "DeepLink’s Cornerstone Investor Mr. Adam Ha, participated in the DEPIN ALLIANCE YACHT PARTY ⛵️panel and introduced DeepLink to the Vietnam market"
    }
  },
  homeNew: {
    cont1:  {
      text1: 'リモートゲームの再定義',
      text2: '遅延のないリモートコントロール、DeepLink で利用可能',
      text3: '現時点ではWindows環境のみサポートします'
    },
    cont2: {
      title: 'リモコンの紹介',
      block1: {
        title: 'リモートコントロールとは',
        text: 'インターネットを通じて、異なる場所にあるコンピュータや機械を遠隔操作することです'
      },
      block2: {
        title: 'リモートコントロールの種類',
        text: 'リモートコントロールは、クライアント/サーバーベースのリモートコントロールとP2Pピアツーピアのリモートコントロールに分けられます'
      },
      block3: {
        title: 'リモートコントロール機能',
        text: '複数のコンピュータ間でファイルを共有したり、遠隔学習の安全性を確保したりすることができます！'
      },
      block4: {
        title1: 'DeepLink',
        title2: 'リモートコントロールのメリット',
        text: 'ブロックチェーン技術を通じて、分散化の問題を解決し、e スポーツ グレードの超低遅延で中断のない最高のゲーム体験を提供します'
      }
    },
    cont3: {
      title: '従来のリモートコントロールツールでは、単純なタスクを達成するのも難しい',
      text1: '接続の遅延',
      text2: '頻繁な切断',
      text3: '低下のフレームレート',
      text4: '高額な費用'
    },
    cont4: {
      title1: 'まるでローカルコンピューターを操作しているように',
      title2: 'スムーズに実現します！',
      desc: 'スマートコントラクト技術で分散化の問題を解決し、高速かつ強力なリモートコントロール機能を体験しましょう',
      text1: 'シンプルで迅速にアクセス',
      text2: '8K対応',
      text3: 'ハイパフォーマンスゲームに最適化',
      text4: '中断なし',
      text5: '最適化された作業ツール',
      text6: '無料で使用可能'
    },
    cont5: {
      title1: '強く、スムーズに',
      title2: 'リモートゲーム',
      title3: 'いつでもどこでも使用可能',
      text1: 'バトルフィールド・アサルト',
      text2: 'リーグ・オブ・レジェンド',
      text3: '恐れのない協定'
    },
    cont6: {
      title1: '重くて高価なゲーミングノートパソコン',
      title2: 'しかしDeepLink',
      title3: '軽くて手頃な価格',
      title4: '',
      text1: '10,000元以上',
      text2: '1.6Kg以上',
      text3: '高性能ノートブック',
      text4: '$0.'
    },
    cont7: {
      title1: '多用途',
      title2: '汎用性が高い',
      block1: {
        text1: '外部',
        text2: '業務用',
        text3: 'ノート'
      },
      block2: {
        text1: 'オフィス, 家庭',
        text2: 'ハイパフォーマンス',
        text3: 'デスクトップマシン',
        text4: 'ハイパフォーマンスワーク',
        text5: 'ハイパフォーマンスゲーム'
      },
      block3: {
        text1: 'クライアント',
        text2: 'コラボレーション',
        text3: 'リモートワーク'
      },
      title3: '強力なテクノロジーをサポートしながら',
      title4: '完全なセキュリティを確保',
      block4: {
        text1: 'アカウントセキュリティ',
        text2: 'ブロックチェーン技術に基づく',
        text3: '複数アカウント保護メカニズム'
      },
      block5: {
        text1: '接続セキュリティ',
        text2: 'DTLS1.2プロトコル保護'
      },
      block6: {
        text1: 'データ・セキュリティ',
        text2: '256ビットAES暗号化'
      },
      block7: {
        text1: 'マシンのセキュリティ',
        text2: 'ユーザー権限が設定できます'
      }
    },
    cont8: {
      title1: 'DeepLinkを使います',
      title2: 'いつでもどこでも接続可能',
      text1: 'ノードのある場所ならどこでも、ハイパフォーマンスゲームをリモートかつシームレスにプレイできます。ブロックチェーンノードコンピュータに接続し、国や場所を問わず、高性能なオンラインゲームをプレイできます！',
      title3: 'DeepLinkを柔軟に使用します',
      title4: 'コラボレーション',
      text2: 'DeepLinkの超低遅延リモート接続は、より強力なコラボレーションを可能にします。デザイナー、プランナー、開発者向けに最適化'
    },
    cont9: {
      title1: '単なるゲームではありません',
      title2: 'さらに従業員の生産性を高めるツールです'
    },
    cont10: {
      title1: 'DeepLinkと一緒に',
      title2: 'スペースとテクノロジーの制約から解放されます',
      block1: {
        name: 'チェ・ヒョン',
        desc: 'ITオペレーション・リモートメンテナンスマネージャー',
        text1: 'リモートジョブサポートの遅延がありません',
        text2: '顧客満足度の向上'
      },
      block2: {
        name: 'ペク サンウ',
        desc: 'ゲーマー',
        text1: '営業用ノートパソコンだけで、出張先でもハイパフォーマンスゲームをプレイすることが可能です',
        text2: 'それが可能だとは思っていませんでした'
      },
      block3: {
        name: 'キム・ジュンホ',
        desc: '営業担当',
        text1: '機器の制限はなし',
        text2: 'ノートパソコンだけでビジネスができます'
      },
      block4: {
        name: 'ユ・ミンギョン',
        desc: 'リモートワーク・デザイナー',
        text1: 'いつでも、どこでも、ダウンロードしなくても仕事ができます',
        text2: 'HQコンピュータに接続して作業'
      },
    },
    cont11: {
      title: 'DeepLink ウォレットとは',
      text1: '安全なアカウント保護システム',
      desc1: 'DeepLinkウォレットは、ブロックチェーン技術、256 ビット AES 暗号化、デバイス情報に基づく多層アカウント保護メカニズムを使用して、堅牢で安全なアカウント保護システムをサポートします。',
      text2: 'トークンを安全に取引および使用することができます',
      desc2: 'ウォレットは、スマートコントラクトやピアツーピアの暗号通貨転送を介した取引よりも安全なトークン取引と使用を可能にします。',
      text3: 'DeepLinkは世界中のどこでも利用できます',
      desc3: 'ウォレットの秘密鍵さえあれば、世界中のどこでも自分のプライベートウォレットにログインし、DeepLink経由でゲームをプレイできます！'
    },
    cont12: {
      title1: '超低遅延リモートゲーム',
      title2: '今すぐDeepLinkを始めましょう！!',
      text1_1: 'ダウンロードしてください',
      text1_2: 'DeepLinkクライアント',
      text2_1: 'DeepLinkに登録します',
      text2_2: '個人ウォレットを作成する必要があります',
      text2_3: '',
      text3_1: 'ウォレット作成後',
      text3_2: '必要なデバイスにアクセスし、DeepLinkを起動します',
      text3_3: ''
    }
  },
  cloudInternet: {
    title: 'クラウドインターネットカフェソリューションプロバイダになる方法',
    text: '現在、世界中の主要なインターネットカフェの分布国は韓国（6,000）、中国（40,000）、ブラジル（10,000）、インドネシア（20,000）、フィリピン（20,000）、ベトナム（15,000）、トルコ（5,000）などです。 さらに、インド、メキシコ、中東、南アメリカ、アフリカ、ヨーロッパなどの国々があります。 グローバルには15万以上のインターネットカフェがあります。 ただし、インターネットカフェ業界は20年の歴史を持ち、伝統的な産業になっています。 ペイバック期間は3〜4年に延び、さまざまなリスクに直面しています。 たとえば、COVID-19パンデミックにより多くのインターネットカフェが閉鎖されました。 したがって、インターネットカフェ業界は、ペイバック期間を短縮し、リスクを軽減し、運営効率を向上させる新しい解決策が必要です。 このようにして、クラウドインターネットカフェソリューションが誕生します。',
    cont2: {
      text1: '何が',
      text2: 'クラウドネットカフェソリューション？',
      text3: 'クラウドサイバーカフェソリューションは、超低遅延レンダリング技術を備えた新しいタイプのサイバーカフェサービスモデルです。クラウドサイバーカフェでは、ゲームやアプリケーションはローカルコンピュータに直接インストールされず、クラウドGPUサーバー上にホストされます。ユーザーは高性能なローカルハードウェアを必要とせず、クラウドサービスを通じてゲームにアクセスしてプレイします。',
      block1: {
        text1: 'ローカルハードウェア要件の削減',
        text2: 'ゲームやアプリケーションがクラウドGPUサーバー上で実行されるため、ローカルコンピュータには高性能なGPUや大容量のストレージは必要ありません。これにより、サイバーカフェのハードウェア投資と保守コストが削減されます。'
      },
      block2: {
        text1: '最新のゲームへの即時アクセス',
        text2: 'ユーザーはダウンロードやインストールを待たずに最新のゲームに即時アクセスできます。すべてのゲームの更新とメンテナンスはクラウドで行われ、ユーザーに便利で効率的な体験を提供します。'
      },
      block3: {
        text1: '高性能なゲーム体験',
        text2: 'ローカルモニターにはミニPC（約100ドル）が必要で、クラウドサイバーカフェでも高性能なゲーム体験を提供できます。これは、ゲームの計算がリモートの高性能GPUサーバー上で行われるためです。'
      },
      block4: {
        text1: 'スペースとエネルギーの節約',
        text2: '低構成のローカルコンピュータは、より少ないスペースを占有し、より少ない電力を消費し、サイバーカフェの運営コストを削減するのに役立ちます。'
      },
      block5: {
        text1: '管理とメンテナンスが容易',
        text2: 'サイバーカフェの管理者は、すべてのコンテンツがクラウドで中央管理されているため、ソフトウェアやゲームをより簡単に管理できます。これにより、ローカルのメンテナンス作業や潜在的な技術的な問題が減少します。'
      }
    },
    cont3: {
      text1: '韓国のGlowsteamクラウドサイバーカフェ',
      text2: 'クラウドサイバーカフェの実際の運用環境'
    },
    cont4: {
      text1: 'クラウドサイバーカフェソリューションは、サイバーカフェ投資家を助けることができます',
      text2: '純利益を3倍に増やし、ペイバック期間を40％短縮します',
      text3: '比較データのダウンロードをクリック'
    },
    cont5: {
      text1: 'クラウドサイバーカフェソリューションプロバイダーの収益はどのようにして得られますか？',
      text2: 'もし利益がサイバーカフェの機械ごとの収益の12％として計算された場合、合計1000のサイバーカフェに100,000台の機械がサービスされ、1日あたり平均10時間の使用時間があり、1台あたりの平均費用が0.8ドルである場合、12％は約0.1ドルに相当します。',
      text3: 'クラウドサイバーカフェソリューションプロバイダーの年間利益は',
      text4: '100,000 * 10 * 0.1 * 365 = 3,650万ドル',
      text5: 'クラウドサイバーカフェソリューションプロバイダーになる方法',
      text6: 'DeepBrainChainとDeepLink技術に基づいて、韓国のHYCONSが完全なクラウドサイバーカフェソリューションを開発しました。DeepBrainChainはGPUコンピューティングパワーを提供し、DeepLinkは超低遅延レンダリング技術を提供し、HYCONSは完全なソリューションを提供します。類推すると、DeepBrainChainがエンジンを提供し、DeepLinkが自動運転システムを提供し、HYCONSが完全な車のソリューションを提供します。さまざまな国のローカルソリューションプロバイダーは、ローカルのサイバーカフェにサービスを提供するためにローカルの支払いシステムをHYCONSに統合するだけでよく、ソリューションプロバイダーは独自のブランドを設定できます。 HYCONSは、ブランドを公開せずに純粋な技術サービスプロバイダーとしてのみ機能します。',
      text7: '詳細については、お問い合わせください：',
      text8: "service{'@'}deepbrainchain.org"
    },
    cont6: {
      title: 'クラウドサイバーカフェ関連資料のダウンロード',
      text1: 'HYCONS紹介',
      text2: 'クラウドサイバーカフェソリューション利益分析',
      text3: 'クラウドサイバーカフェデータセンターアーキテクチャダイアグラム',
      text4: 'クラウドサイバーカフェソリューションテストおよびインストールマニュアル',
      text5: 'DeepLinkプロトコル紹介'
    }
  },
  reward2: {
    desc: {
      title1: 'DeepLinkノード',
      text1: 'ノードの所有者のみが、CPUトラフィックマイニングと個人GPUマシンマイニングに参加できます。報酬の総数はDLCトークンの12％であり、そのうち4％がCPUトラフィックノードに、8％が個人GPUノードに割り当てられます。トークンの総量は120億です。各ノードの出力は、実際に販売されたノード数に関連しています。すべてが販売された場合、各ノードは10万DLCを生産します。ノードの10％のみが販売された場合、各ノードは100万DLCを生産します。',
      title2: 'ノードの総数：120,000、追加発行なし',
      text2: '各ノードには追加のエアドロップが提供されます',
      text3: '各ノードでマイニングされるDLCの最小量',
      text4: '初期価格',
      text5: '最高価格'
    },
    btn_text: 'ノードを購入',
    table: {
      tier: '層',
      license: 'ノードライセンス価格 (USDT)',
      total_num: 'ライセンスの合計',
      total_usdt: 'USDT 総額',
      buy: '購入'
    },
    intro: {
      title: 'ノードマイニングの紹介',
      text1_1: 'DeepLinkのネットワークユーザーが増えるほど、ネットワークトラフィックと計算能力の需要が増え、それに伴いDeepLinkネットワークにインフラを提供するために、より多くのCPUトラフィックマシンとGPU計算マシンが必要になります。これにより、分散化が達成され、DeepLinkの成長が加速されます。ノードの権限を購入することで、マイニングに参加して報酬を得る機会が得られます。質問がありますか？',
      text1_2: 'と',
      text1_3: '一緒に上でチャットしてください。',
      text2: '各ノードはNFTの形で発行され、さらにBSCチェーンで30,000 DLCのエアドロップを受け取ります。エアドロップは取引所への上場前に開始され、エアドロップDLCの20％はすぐにロック解除され、80％は8か月かけてロック解除されます。\n（DLCトークンプロセスの表示：ウォレットを開いてBSCネットワークを選択し、[トークンの追加]を選択して、契約アドレスを入力します：0xD610Be30b06A3828A27D608a38F1E85faAFdEDcC）',
      text3: '2024年10月31日以前にノードを他の人に転売することはできません。',
      text4: 'マイニングされたトークンの25％が即座にアンロックされ、残りの75％が150日間かけて線形にアンロックされます。',
      text5: '上場後10日以内にマイニングが開始されない場合、報酬はエアドロップを通じて配布され、エアドロップは7日ごとに行われます。エアドロップのルールはマイニングの産出ルールと同じで、毎日の総産出量は410万DLCであり、ノードの総販売数に基づいて均等に分配されます。25％が即座にアンロックされ、残りの75％が150日間かけて線形にアンロックされます。マイニングがすでに開始されている場合は、報酬を受け取るために対応するマシンが必要です。',
      text6: '20 個以上のノードを購入する場合、ノード操作を委任できます。1,000 個以上のノードを個別に購入する投資家には、ノード操作に関する完全なサポートが提供されるほか、協力的なオペレーターとドッキングしてノードの運用を支援する機能も提供されます。',
      text7: '1 台の仮想マシンで 20 個の NFT ノードをサポートでき、収益は 20 倍になります。'
    },
    question: {
      ques1: 'ノードNFTは合計でいくつ販売されますか？',
      ans1: '合計で12万個',
      ques2: '各ノードNFTの費用はいくらですか？',
      ans2: '10段階に分かれており、価格は30Uから155Uまで、公式ウェブサイトで販売され、売り切れ次第終了します',
      ques3: 'ノードNFTの購入は一度きりの支払いですか？',
      ans3: 'はい、ノードの購入は一度きりの支払いで、今後追加の料金は発生しません',
      ques4: 'ノードNFTを購入すると追加のエアドロップがありますか？',
      ans4: 'はい、各ノード所有者には30,000DLCのエアドロップがあります。取引所への上場前にエアドロップが開始され、DLCの20％が即時にアンロックされ、80％が8ヶ月にわたってアンロックされます',
      ques5: '具体的なマイニングメカニズムは何ですか？',
      ans5: '総報酬は120億DLCです。トラフィックノードの総収益は40億DLCで、個人GPUマシンノードの総収益は80億DLCです。ノードNFTを購入することで、これらのマイニングの両方に同時に参加できます。最初の4年間の報酬は年間15億DLCで、4年ごとに半減します。毎日の報酬総数は411万DLCで、ノードNFT所有者のみに分配されます。採掘されたトークンの25％が即時にアンロックされ、75％が150日間で線形的にアンロックされます',
      ques6: '購入したノードNFTは、CPUトラフィックマシンと個人GPUマシンの両方のマイニングに参加できますか？',
      ans6: 'はい',
      ques7: 'CPU帯域幅トラフィックマシンノードとは何ですか？',
      ans7: 'CPU帯域幅トラフィックマシンノードは、DeepLinkネットワークの計算とトラフィック転送のコアタスクを担っています。マイニングのモデルを通じて、DeepLinkはより多くの人が参加して収益を得ることを可能にしています。トラフィックノードの総収益は40億DLCです',
      ques8: '最初の年の帯域幅トラフィックマシンの総報酬はいくらですか？',
      ans8: '最初の年の報酬は5億DLCで、毎日の報酬は約137万DLCです',
      ques9: '各CPU帯域幅トラフィックマシンノードの収益と帯域幅の関係は？',
      ans9: '基礎帯域幅は10Mb上り帯域幅です。帯域幅値は10Mb上り帯域幅で10です。帯域幅は10の倍数でのみ有効です。例えば、45Mb上り帯域幅の場合、帯域幅値は40として計算されます。帯域幅は実際の使用に応じて30％増加します。最終的に実際の帯域幅値に応じて収益が分配されます',
      ques10: '帯域幅トラフィックマシンノードの構成要件は高いですか？構成情報はありますか？マイニングに参加するにはどうすればいいですか？',
      ans10: '構成要件は高くありません。vCPU 1C + メモリ 2G + 50G ハードディスクです。AWSでのレンタル費用は月額約10 USDで、帯域幅は実際の使用に応じて課金されます。AWSでCPU仮想マシンをレンタルした後、DeepLinkトラフィックノードマイニングプログラムをインストールし、ウォレットアドレスを作成し、ノードNFTをウォレットに転送してマイニング機能を起動すれば、マイニングに参加できます',
      ques11: '個人GPUマシンノードとは何ですか？',
      ans11: '個人は自分のGPUコンピュータを提供してマイニングに参加できます。GPUマシンはDeepLinkプロトコルにクラウドゲームのインフラストラクチャサービスを提供します。個人GPUマシンノードの総収益は80億DLCです',
      ques12: '最初の年の個人GPUマシンノードの総報酬はいくらですか？',
      ans12: '最初の年の報酬は10億DLCで、毎日の報酬は約274万DLCです',
      ques13: '個人GPUマシンノードのマシン構成はどうですか？マイニングに参加するにはどうすればいいですか？',
      ans13: 'GPUマシンの構成要件は、CPU周波数が3.0GHz以上、GPUはNvidia Geforceシリーズ：20xx、30xx、40xxで、メモリは16G以上です'
    }
  }
}